define('dynforms-client/models/status', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var tipoPagoModel = _emberData.default.Model.extend({
    status: _emberData.default.attr('string'),
    formularios: _emberData.default.hasMany('formulario', { async: true })
  });

  exports.default = tipoPagoModel;
});