define('dynforms-client/router', ['exports', 'dynforms-client/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('login');
    this.route('home', { path: "/home" });
    this.route('solicitud');
    this.route('reports');

    this.route('formularios', function () {
      this.route('new');
      this.route('reports');
      this.route('show', { path: '/show/:id_formulario' });
      this.route('edit', { path: '/edit/:id_formulario' });
    });

    this.route('dependencies', function () {
      this.route('index', { path: '/' });
    });

    this.route('processes', function () {
      this.route('index', { path: '/' });
      this.route('show', { path: '/show/:id' });
      this.route('citizen', { path: '/citizen/:id' });
    });

    this.route('users', function () {});
  });

  exports.default = Router;
});