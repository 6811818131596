define('dynforms-client/controllers/formularios/index', ['exports', 'dynforms-client/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    selectedTipoPago: null,
    selectedEstado: null,
    tramiteSearch: '',
    changeView: false,
    viewDelete: false,
    viewPublish: false,
    filterStatus: 0,
    isSelectedEstados: false,
    filterActive: false,
    filter: '',
    namesSearch: '',
    status: '',
    loading: true,
    filteredContent: function () {
      var localItem = JSON.parse(localStorage[_environment.default.APP.LS]);
      var role = localItem.json.role;
      var filter = this.get('filter');
      var namesSearch = this.get('namesSearch');
      var filterTramite = this.get('tramiteSearch');
      var filterEstadoId = this.get('selectedEstado.id');
      var filterEstadoName = this.get('selectedEstado.estado');
      var filterDependencia = this.get('selectedDependencia.name');
      var filterPago = this.get('selectedTipoPago');
      var filterStatus = this.get('status');
      var changeView = this.get('changeView');
      var rx = new RegExp(filter, 'gi');
      var nombreabuscar = new RegExp(namesSearch, 'gi');
      var formularios = this.get('model');

      this.set('viewDelete', role == 'SA', role == 'AD', role == 'EAD' ? true : false);
      this.set('viewPublish', role == 'SA' ? true : false);

      if (filterTramite !== null || filterTramite !== '') {
        var _rxT = new RegExp(filterTramite, 'gi');
      }

      if (filterEstadoName !== null) {
        var _rxEI = new RegExp(filterEstadoId, 'i');
        var _rxEN = new RegExp(filterEstadoName, 'gi');
      }
      if (filterDependencia !== null) {
        var _rxD = new RegExp(filterDependencia, 'i');
      }

      if (filterPago != null) {
        var _rxP = new RegExp(filterPago._data.tipo_pago, 'gi');
      }
      if (filterStatus !== null || filterStatus !== "") {
        var _rxS = new RegExp(filterStatus, 'gi');
      }

      if (namesSearch.length > 0) {
        var response = true;

        return formularios.filter(function (formulario) {
          if (formulario._data.nombre !== null || formulario._data.nombre !== null) {
            response = formulario._data.nombre.match(nombreabuscar);
          } else {
            response = false;
          }

          return response;
        });
        // busqueda por  tramite
      } else if (filter.length > 0) {

        return formularios.filter(function (formulario) {
          if (formulario._data.n_estado !== undefined && formulario._data.n_estado !== null) {
            return formulario._data.n_tramite.name.match(rx) || formulario._data.n_estado.estado.match(rx) || formulario._data.n_tipo_pago.tipo_pago.match(rx) || formulario._data.nombre.match(rx);
          } else if (formulario._data.n_dependencia !== undefined && formulario._data.n_dependencia !== null) {
            return formulario._data.n_tramite.name.match(rx) || formulario._data.n_dependencia.name.match(rx) || formulario._data.nombre.match(rx);
          }
        });
      } else {
        // busqueda por combo seleccionado
        if (filterTramite.length > 0 || filterEstadoName !== null || filterPago !== null || filterDependencia !== null && filterDependencia !== undefined) {

          return formularios.filter(function (formulario) {
            var response = true;

            if (filterTramite.length > 0 && filterTramite !== undefined) {
              if (formulario._data.n_tramite !== null && formulario._data.n_tramite !== undefined) {
                response = formulario._data.n_tramite.name.match(rxT);
              } else {
                response = false;
              }
              response = formulario._data.n_tramite.name.match(rxT);
            }

            if (filterEstadoName != null && filterEstadoName !== undefined && response) {
              if (formulario._data.n_estado !== null && formulario._data.n_estado !== undefined) {
                response = formulario._data.n_estado.id.toString().match(rxEI) && formulario._data.n_estado.estado.match(rxEN);
              } else {
                response = false;
              }
            }
            if (filterDependencia != null && filterDependencia !== undefined && response) {
              if (formulario._data.n_dependencia !== null && formulario._data.n_dependencia !== undefined) {
                response = formulario._data.n_dependencia.name.match(rxD);
              } else {
                response = false;
              }
            }
            if (filterPago != null && filterPago !== undefined && response) {
              if (formulario._data.n_tipo_pago !== null && formulario._data.n_tipo_pago !== undefined) {
                response = formulario._data.n_tipo_pago.tipo_pago.match(rxP);
              } else {
                response = false;
              }
            }

            return response;
          });
          /* Filtrado por estatus*/
        } else if (filterStatus.length > 0 || filterStatus !== null || filterStatus !== "") {
          return formularios.filter(function (formulario) {
            var response = true;

            if (filterStatus != null && filterStatus !== undefined && filterStatus !== "" && response) {
              if (filterStatus !== 0) {
                if (changeView) {
                  response = formulario._data.status.match(rxS);
                }
              }
            }
            //
            return response;
          });
        } else {

          return formularios.filter(function (formulario) {
            return formulario._data.id !== undefined ? true : false;
          });
        }
      }
    }.property('content', 'model', 'filter', 'model.@each', 'tramiteSearch', 'selectedEstado', 'selectedTipoPago', 'changeView', 'status', 'selectedDependencia', 'namesSearch'),
    actions: {
      filterToggle: function filterToggle() {
        this.toggleProperty("filterActive");
      },

      createNewFormulario: function createNewFormulario() {
        this.transitionToRoute('/formularios/new');
      },
      clearSearch: function clearSearch() {
        this.set('selectedTipoPago', null);
        this.set('selectedEstado', null);
        this.set('tramiteSearch', '');
        this.set('namesSearch', '');
        this.set('status', '');
        this.set('changeView', true);
        Ember.$(".navStatus").removeClass("active");
        Ember.$("#status-" + 0).addClass("active");
      },

      activeFormulario: function activeFormulario(params) {
        var value = "[" + JSON.stringify(params) + "]";
        var status = value[0].status;
        var id = value[0].id;
        var msg = "";
        var estatusNew = "";
        var activar = false;
        var botones = {};
        var name = "<b>" + value[0].nombre + "</b>";
        var itemSelected = {};

        value = JSON.parse(value);

        if (status === "1") {
          activar = false;
          botones = { "Aceptar": false };
          msg = "No se puede publicar el formulario. <br>" + name + ".<br>está incompleto o no cuenta con campos para mostrar.";
          value[0].status = 1;
        } else if (status === "2") {
          activar = true;
          botones = { "Publicar": activar, "Cancelar": false };
          msg = "¿Éstá seguro de querer publicar el formulario? <br> " + name;
          value[0].status = 3;
          estatusNew = "Publicado";
        } else if (status === "3") {
          activar = true;
          botones = { "Publicar": activar, "Cancelar": false };
          msg = "El formulario <br>" + name + ".<br>está publicado  ¿Desea actualizar la publicacion?";
          value[0].status = 3;
          estatusNew = "Republicado";
        }

        this.set('changeView', false);
        /*window.prompt(msg , {
          overlayspeed:'fast',
          promptspeed:'fast',
          title: "Formulario",
          buttons:botones,
          submit: function (e, answer) {*/
        id = params.id;
        // if (answer === true) {
        // var itemSelected = '{ "id":'+id+',  "formulario": {"tramite":'+params._data.n_tramite.id+', "dependencia":'+params._data.n_dependencia.id+'} }';
        // itemSelected = JSON.parse(itemSelected);
        // itemSelected = JSON.parse(`{ "id": ${id}, "formulario": { "tramite": ${params._data.n_tramite.id}, "dependencia": ${params._data.n_dependencia.id} }}`);
        itemSelected = JSON.parse('{ "id": ' + id + ', "formulario": { "tramite": ' + params.id + ', "dependencia": ' + params.id_dependencia + ' }}');
        Ember.$('#' + id).removeClass('fa-check-square').addClass('fa-check-square-o ').css("color", "#4D92DF");

        /*$.ajax({
          url: config.APP.REST_WSPREFIX+"/"+config.APP.WSSUFIX+'/publicaciones/'+id,
          type: 'PUT',
          dataType: 'json',
          contentType: 'application/json',
          data:  JSON.stringify(itemSelected)
        })
        .done(function() {
          $(".estatus-form"+"."+id).text(estatusNew);
        });*/
        // }
        // }
        // });
      }
      /*changeStatus(status) {
        Ember.$(".navStatus").removeClass("active");
        Ember.$("#status-" + status).addClass("active");
        this.set('status', status);
        this.set('changeView', true);
        return false;
      },
      advancedSearch() {}*/
    }
  });
});