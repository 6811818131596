define('dynforms-client/models/dependency', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var dependencyModel = _emberData.default.Model.extend({
		name: _emberData.default.attr('string'),
		siglas: _emberData.default.attr('string'),
		formularios: _emberData.default.hasMany('formulario', { async: true })

	});

	exports.default = dependencyModel;
});