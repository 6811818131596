define('dynforms-client/models/cambio_pend', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var pendingChangeModel = _emberData.default.Model.extend({
		idPendingChange: _emberData.default.attr('number'),
		idRelFa: _emberData.default.attr('number'),
		fechaCambio: _emberData.default.attr('date'),
		export: _emberData.default.attr('boolean')
	});

	exports.default = pendingChangeModel;
});