define('dynforms-client/mixins/formulario', ['exports', 'dynforms-client/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    needs: ['formularios/index'],
    store: Ember.inject.service(),
    isSaved: false, // START WITH FALSE
    isActionType: false,
    isActive: true,
    isNew: false,
    isComplete: true,
    isPublished: false,
    isSelectedEstados: false,
    isSelectedDependencias: false,
    selectedEstado: null,
    selectedTipoPago: null,
    selectedTramite: null,
    selectedDependencia: null,
    selectedTipoForm: null,
    jsonForm: null,
    isUpdated: false,
    isEncuesta: false,
    status: null,
    admin: false,
    id_form: null,
    viewTipoForm: false,
    editandoForm: false,
    tipoform: false,
    queryParams: ['page'],
    page: 1,
    offset: 0,
    plantilla: '',
    usuario: Ember.Object.create({}),
    checkCredentials: Ember.observer('credentials', function () {
      var controller = this;

      if (this.credentials) {
        this.get("store").find('user', this.credentials.get("userId")).then(function (usuario) {
          controller.set("usuario", usuario);
        }).catch(function (err) {
          controller.set("isActive", true); // Not able to save
        });
      }
    }),
    /*loadFormulario: function() {
      this.set('selectedEstado', null);
      this.set('selectedTipoPago', null);
      this.set('selectedTramite', null);
      this.set('selectedDependencia', null);
      this.set('selectedTipoForm', null);
      this.set('nameform', null);
      this.set('startdate', null);
      this.set('finaldate', null);
      this.set('plantillas', null);
    },*/
    processDate: function processDate() {
      /*if (navigator.appName === 'Microsoft Internet Explorer') {
        let ua = navigator.userAgent,
        re = new RegExp("MSIE ([0-9]{1,}[\\.0-9]{0,})");
         if (re.exec(ua) !== null){
          rv = parseFloat( RegExp.$1 );
        }
      } else if(navigator.appName === "Netscape") {
        /// in IE 11 the navigator.appVersion says 'trident'
        /// in Edge the navigator.appVersion does not say trident
        if (navigator.appVersion.indexOf('Trident') === -1) {rv = 12;}
        else {rv = 11;}
      }
       if (explorer === 12){
        dateinicio = controller.get('startdate');
        datefin = controller.get('finaldate');
      } else {
        let diaInitial = controller.get('initialDia');
        let mesInitial = controller.get('initialMes');
        let anioInitial = controller.get('initialAnio');
         let fechaExplorer = JSON.stringify(anioInitial.description)+"-"+ JSON.stringify(mesInitial.description)+ "-"+ JSON.stringify(diaInitial.description);
        fechaExplorer = fechaExplorer.replace(/\"/g, "");
        dateinicio = fechaExplorer;
         let diaFinal = controller.get('finalDia');
        let mesFinal = controller.get('finalMes');
        let anioFinal = controller.get('finalAnio');
         let fechaExplorerFinal = JSON.stringify(anioFinal.description)+"-"+ JSON.stringify(mesFinal.description)+ "-"+ JSON.stringify(diaFinal.description);
        fechaExplorerFinal = fechaExplorerFinal.replace(/\"/g, "");
        datefin = fechaExplorerFinal;
      }
       let sdate = null;
      let fdate = null;
       function convertDateFormat (string) {
        let info = string.split('-');
        //mes dia año
        return  info[1] + '/' + info[2] + '/' + info[0];
      }
       if (dateinicio !== ""  && dateinicio !== null && dateinicio !== undefined && datefin !== undefined && datefin !== "" && datefin !== null){
        let date1 = convertDateFormat(dateinicio);
        let date2 = convertDateFormat(datefin);
        sdate = new Date(date1);
        fdate = new Date (date2);
      }*/
    },
    browserCheck: Ember.observer('initialDia', 'initialMes', function () {
      var controller = this;
      var rv = -1; // Return value assumes failure.

      if (navigator.appName === 'Microsoft Internet Explorer') {
        var ua = navigator.userAgent;
        var re = new RegExp("MSIE ([0-9]{1,}[\\.0-9]{0,})");
        rv = re.exec(ua) !== null ? parseFloat(RegExp.$1) : -1;
      } else if (navigator.appName === "Netscape") {
        /// in IE 11 the navigator.appVersion says 'trident'
        /// in Edge the navigator.appVersion does not say trident
        if (navigator.appVersion.indexOf('Trident') === -1) {
          rv = 12;
        } else {
          rv = 11;
        }
      }

      var explorer = rv;

      if (explorer === 11) {
        var diaInitial = controller.get('initialDia');
        var mesInitial = controller.get('initialMes');
        var anioInitial = controller.get('initialAnio');

        if (anioInitial !== null && anioInitial !== undefined && anioInitial !== "") {
          var fechaExplorer = JSON.stringify(anioInitial.description) + "-" + JSON.stringify(mesInitial.description) + "-" + JSON.stringify(diaInitial.description);
          console.log('before break 1');
          fechaExplorer = fechaExplorer.replace(/\"/g, "");
          startDate = fechaExplorer;
          this.set('startdate', fechaExplorer);
        }

        var diaFinal = controller.get('finalDia');
        var mesFinal = controller.get('finalMes');
        var anioFinal = controller.get('finalAnio');

        if (anioFinal !== null && anioFinal !== undefined && anioFinal !== "") {
          var fechaExplorerFinal = JSON.stringify(anioFinal.description) + "-" + JSON.stringify(mesFinal.description) + "-" + JSON.stringify(diaFinal.description);
          console.log('before break 1');
          fechaExplorerFinal = fechaExplorerFinal.replace(/\"/g, "");
          this.set('finaldate', fechaExplorerFinal);
        }
      }

      // TRUE = internet explorer
      localStorage.setItem('typeExplorer', explorer === 12 ? false : true);
    }),
    checkFormulario: Ember.observer('selectedDependencia', 'nameform', 'selectedEstado', 'selectedTramite', 'selectedTipoPago', 'selectedTipoForm', 'startdate', 'finaldate', function () {
      var controller = this;
      var localItem = JSON.parse(localStorage[_environment.default.APP.LS]);
      var role = localItem.json.role;
      var isEstadoActivo = false;
      var isDependenciaActivo = false;

      this.set('isSaved', false);
      localStorage.setItem('href', _environment.default.APP.REST_WSPREFIX + "/" + "api/v2");

      //verifica la dependencia o estado asociado al usuario'
      //verifica  si se evalua como formulario de admin/editor o de superadmin

      // Config by ROLE
      if (role === 'SA') {
        this.set('viewTipoForm', this.get('editandoForm') ? false : true);
        this.set('isEncuesta', this.get('selectedTipoForm.id') === 1 ? true : false);
        this.set('isSelectedEstados', false);
        this.set('admin', true);

        isEstadoActivo = false;
        isDependenciaActivo = true;

        // Es usuario con rol de actas
      } else if (role === "ED" || role === "AD") {
        this.set('viewTipoForm', false);
        this.set('isSelectedEstados', false);

        isEstadoActivo = true;
        // Busqueda del estado

        // roles de encuestas
        /*} else if (role === "EED" || role === "AAD" ) {
          this.set('viewTipoForm', false);
          this.set('isSelectedEstados',false);
          isEstadoActivo = false;
          isDependenciaActivo = false;*/
      } else {}
        // this.set('viewTipoForm', true);


        // console.log("viewTipoForm", this.get("viewTipoForm"))

      this.set('isUpdated', true);

      // PARAMS to eval
      var isEstado = this.get('selectedEstado') !== null && this.get('selectedEstado') !== undefined ? false : true;
      var isPago = this.get('pago') !== null && this.get('pago') !== undefined ? false : true;
      var isTramitef = this.get('selectedTramite') !== null && this.get('selectedTramite') !== undefined ? false : true;
      var isNameformf = this.get('nameform') !== null && this.get('nameform') !== undefined && this.get('nameform') !== "" ? false : true;
      var isDependencia = this.get('selectedDependencia') !== null && this.get('selectedDependencia') !== undefined ? false : true;
      var isTramite = this.get('selectedTramite') !== null && this.get('selectedTramite') !== undefined ? false : true;
      var isNameform = this.get('nameform') !== null && this.get('nameform') !== undefined && this.get('nameform') !== "" ? false : true;
      var startDate = this.get('startdate') !== null && this.get('startdate') !== undefined && this.get('startdate') !== "" ? false : true;
      var finalDate = this.get('finaldate') !== null && this.get('finaldate') !== undefined && this.get('finaldate') !== "" ? false : true;

      // EVAL si el formulario es Encuesta, Trámite
      if (this.get('admin')) {

        if (isEstadoActivo) {
          // Verificamos si es un super o es un admin de actas o es un editor
          if (this.get('viewTipoForm')) {
            // VALIDATE by true
            if (!isEstado && !isTramitef && !isPago && !isNameformf) {}
            if (isEstado === false && isTramitef === false && isPago === false && isNameformf === false) {
              this.set('isActive', false);
            } else {
              this.set('isActive', true);
            }
          } else {
            // validamos el nombre y el tipo de pago para admin AD y editor ED
            if (isPago === false && isNameformf === false) {
              this.set('isActive', false);
            } else {
              this.set('isActive', true);
            }
          }
        } else {
          this.set('isActive', false);
        }
        // Diferenciamos si es una encuesta
        //
      } else {

        if (this.get('isSelectedDependencia') === true) {} else {

          // if () {
          // if (startDate === false){
          // finalDate = (new Date(this.get('finaldate')) > new Date(this.get('startdate'))) ? false:true;
          // }
          // }
          // validamos pára IE
        }

        if (isDependencia === false && isTramite === false && isNameform === false && startDate === false && finalDate === false) {
          this.set('isActive', false);
        } else {
          this.set('isActive', true);
        }
      }

      // this.set('isActive', false);
      // console.log('validated => ', this.get('isActive'));
    }),
    rulesFormulario: function rulesFormulario() {

      return true;
    },

    actions: {
      changeTipoForm: function changeTipoForm(opt) {
        this.set('tipoForm', opt);
      },
      selectPago: function selectPago(opt) {
        this.set('pago', opt);
      },
      changeEstado: function changeEstado(opt) {
        this.set("estado", opt);
      },
      changeTramite: function changeTramite(opt) {
        this.set('tramite', opt);
      },
      changePlantilla: function changePlantilla(opt) {
        this.set('plantilla', opt);
      },
      changeDep: function changeDep(opt) {
        this.set('dependencia', opt);
      },
      previousPage: function previousPage() {
        // Just a small tweak to the previous button
        // if by any chance the user hits a url that
        // has a page that is higher than the actual total pages (this is only possible manually)
        // as he tries to come back to the previous page
        // he will get the last possible page number
        var totalPages = Math.ceil(this.get('total') / this.get('limit'));
        if (this.decrementProperty('page') > totalPages) {
          this.set('page', totalPages);
        }

        this.transitionToRoute({
          queryParams: {
            page: this.get('page')
          }
        });
      },
      nextPage: function nextPage() {
        this.transitionToRoute({
          queryParams: {
            page: this.incrementProperty('page')
          }
        });
      },
      ModalPreview: function ModalPreview() {
        Ember.$(function () {
          Ember.$("#dialog-message").dialog({
            closeOnEscape: false,
            modal: true,
            height: 700,
            width: 700,
            buttons: {
              Ok: function Ok() {
                Ember.$(this).dialog("close");
              }
            }
          });
        });
      },
      UpdateFormulario: function UpdateFormulario(status) {
        Ember.$('body,html').animate({ scrollTop: 0 }, 500);

        var rv = -1; // Return value assumes failure.
        var explorer = rv;
        var controller = this;
        var formulario = this.get('model');
        var estado = this.get('estado');

        var dependencia = this.get('dependencia') ? this.get('dependencia')["id"] : null;
        var tramite = this.get('tramite') ? this.get('tramite')["id"] : null;
        var tipoPago = this.get('pago') ? this.get('pago').get("id") : null;

        var idusuario = this.get('id_user');
        var nameform = this.get('nameform');
        var startDate = this.get("startdate");
        var finalDate = this.get("finaldate");
        var admin = this.get('admin');
        var flag = false;
        var ctrlDateInicial = false;
        var faltantes = 0;
        var faltaElementoAgrupado = 0;
        var faltaName = false;
        var captcha = this.get('captcha');
        var tipo_forma = null;

        // Validate Browser

        // console.log("formulario ==> ", formulario);
        // console.log("estado => ", estado);
        // console.log("controller.get('id_estado') ??? ", controller.get('id_estado'));
        // console.log("dependencia => ", dependencia);
        // console.log("controller.get('dependencia') ??? ", controller.get('dependencia'));
        // console.log("tramite ===> ", tramite);
        // console.log("tipoPago ====> ", tipoPago);

        if (estado === "" || estado === null || estado === undefined) {
          estado = controller.get('id_estado');
        }

        // THIS is dumb, why declare it in vars then replace it
        // if (dependencia === "" || dependencia === null || dependencia === undefined) {
        // dependencia = controller.get('dependencia');
        // }

        if (tramite === null || tramite === undefined || tramite === '') {
          flag = true;
          controller.set('txEvent', true);
          controller.set('txMessage', 'Debe seleccionar un tramite');

          return;
        }

        console.log("formulario ? ", formulario);
        console.log('formulario.get("fecha_inicio") ?? ', formulario.get("fecha_inicio"));
        console.log('formulario.get("fecha_fin") ????? ', formulario.get("fecha_fin"));

        if (admin) {
          if (tipoPago === null || tipoPago === undefined || tipoPago === '') {
            console.log("ERRROT tipoPago ===> ", tipoPago);
            flag = true;
            controller.set('txEvent', true);
            controller.set('txMessage', 'Debe seleccionar el tipo de pago del tramite');

            return;
          }
          /*if(estado === null || estado === undefined || estado === '') {
            flag = true;
            controller.set('txEvent', true);
            controller.set('txMessage','Debe seleccionar el estado al que pertenece el Tramite');
          }*/
        } else {
          if (dependencia === null || dependencia === undefined || dependencia === '') {
            flag = true;
            controller.set('txEvent', true);
            controller.set('txMessage', 'Debe seleccionar la dependencia al que pertenece el Tramite');

            return;
          }

          // REFACTOR DATES  !!!!

          console.log('this.get("startdate") ==> ', this.get("startdate"));

          if (this.get("startdate") === null || this.get("startdate") === undefined || this.get("startdate") === '') {
            // flag = true;
            ctrlDateInicial = true;
            controller.set('txEvent', true);
            controller.set('txMessage', 'Debe seleccionar fecha de inicio de la encuesta');

            return;
          } else {
            startDate = this.get("startdate");
          }

          if (formulario.get("fecha_fin") === null || formulario.get("fecha_fin") === undefined || formulario.get("fecha_fin") === '') {
            // flag = true;
            controller.set('txEvent', true);
            controller.set('txMessage', 'Debe seleccionar fecha de finalización de la encuesta');

            return;
          } else {
            finalDate = this.get("finaldate");

            if (ctrlDateInicial === false) {
              if (flag === false) {
                flag = new Date(finalDate) < new Date(startDate) ? true : false;
                if (flag) {
                  controller.set('txEvent', true);
                  controller.set('txMessage', 'Debe seleccionar fecha de finalización de la encuesta superior a la inicial');
                }
              }
            }
          }
        }

        if (tipoPago === undefined) {
          tipoPago = null;
        }
        if (dependencia === undefined) {
          dependencia = null;
        }

        /* VAlidacion de repetidos*/
        var data = localStorage.getItem('JsonForm');
        var att_name = "";
        var obj = null;
        if (data !== "") {
          obj = JSON.parse(data);
        }

        var numElem = 0;
        var esEstepper = null;
        var stepper_num = null;
        var estepperNoAlign = 0;
        var out_of_stepper = false;

        // REFACTOR activate loading after main form validation
        // Convert tu ember vars
        //

        Ember.$(".guardar").prop("disabled", true);
        Ember.$(".publicar").prop("disabled", true);
        Ember.$("#loading").css("display", "block");

        if (obj !== null) {
          Ember.$.each(obj.atributos, function (i) {
            console.log('elementos a guardar despues de editado ', i);
            numElem = i;
            if (obj.atributos[i].type === 'header') {
              if (obj.atributos[i].element === 'Stepper') {
                esEstepper = i;
                stepper_num = stepper_num + 1;
              }
            }

            if (esEstepper > 0 && numElem > 0 && stepper_num === 1) {
              estepperNoAlign = 1;
            }
            if (estepperNoAlign === 1) {
              controller.set('txMessage', "Tienes elementos arriba de la primera sección " + obj.atributos[i].label + " . Por favor desciéndelos. para continuar");
              controller.set('txEvent', true);
              faltantes += 1;
              out_of_stepper = true;
            }

            if (obj.atributos[i].type !== 'hr') {
              if (obj.atributos[i].name === undefined) {
                faltantes += 1;

                if (obj.atributos[i].type === 'header') {
                  controller.set('txMessage', "Existen un elemento " + obj.atributos[i].element + " que no tienen un nombre asignado. Por favor asignelos. para continuar");
                } else {
                  controller.set('txMessage', "Existen un elemento " + obj.atributos[i].type + " que no tienen un nombre asignado. Por favor asignelos. para continuar");
                }
                controller.set('txEvent', true);
              }
            }

            if (obj.atributos[i].type === 'selectnested') {
              if (obj.atributos[i].etiqueta2 === undefined) {
                controller.set('txMessage', "Es necesario agregar la Etiqueta 2 del elemento Anidado");
                faltantes += 1;
                controller.set('txEvent', true);
                flag = true;
              }
            }

            if (obj.atributos[i].type === 'button') {
              if (obj.atributos[i].url_button === undefined) {
                faltantes += 1;

                controller.set('txMessage', "Existe un elemento " + obj.atributos[i].type + " que no tiene asignado una url. Por favor asignelos. para continuar");
                controller.set('txEvent', true);
              }
              if (obj.atributos[i].label === undefined) {
                faltantes += 1;

                controller.set('txMessage', "Existe un elemento " + obj.atributos[i].type + " que no tiene asignado una etiqueta. Por favor asignelos. para continuar");
                controller.set('txEvent', true);
              }
            }

            if (obj.atributos[i].name !== undefined) {
              att_name += "," + obj.atributos[i].name;
            }
          });
        } else {
          faltantes = 1;
          controller.set('txMessage', "No Existen  elemento para agregar. Por favor asignelos. para continuar");
          controller.set('txEvent', true);
        }

        faltaName = faltantes === 0 ? false : true;

        var uniqueList = att_name.split(',').filter(function (allItems, i, a) {
          return i === a.indexOf(allItems);
        }).join(',');

        if (uniqueList !== att_name) {
          controller.set('txMessage', 'Existen campos Repetidos favor de verificar');
          controller.set('txEvent', true);
          flag = true;
        }

        /*var sdate = null;
        var fdate = null;
         console.log('this.get("startdate") => ', startdate);
         if (startDate !== undefined && finalDate !== undefined){
          console.log("startDate => ", startDate);
          console.log("finalDate => ", finalDate);
          sdate = new Date(startDate);
          fdate = new Date(finalDate);
           console.log("sdate => ", sdate)
          console.log("fdate => ", fdate);
        }*/

        var thereAreElementsGroup = false;
        var numElements = 0;

        console.log('faltantes = ', faltantes);

        if (obj !== null) {
          Ember.$.each(obj.atributos, function (i) {
            if (obj.atributos[i].type === 'header') {
              if (obj.atributos[i].element === 'Stepper') {
                tipo_forma = true;
              }
            } else if (obj.atributos[i].type === 'elementsGroup') {
              console.log('validando element elementsGroup');
              thereAreElementsGroup = true;
              if (obj.atributos[i].elements !== undefined) {
                numElements = obj.atributos[i].elements.length;
                if (numElements === undefined) {
                  numElements = 1;
                }
              } else {
                numElements = 0;
              }
            } else {
              thereAreElementsGroup = false;
            }

            if (thereAreElementsGroup) {
              if (numElements < 2) {
                faltaElementoAgrupado += 1;
                controller.set('txMessage', 'El Grupo de Elementos debe contar con 2 o más  elementos, por favor agrégalos para continuar.');
                controller.set('txEvent', true);
                // (etiqueta)
              }
            }
          });
        }

        var faltaEA = faltaElementoAgrupado === 0 ? false : true;

        if (data !== null && data !== undefined && flag === false && faltaName === false && faltaEA === false && out_of_stepper === false) {

          var atributos = JSON.parse(data);
          var bodyForm = {
            "id": formulario.get('id'),
            "atributos": atributos.atributos,
            "formulario": {
              "usuario": idusuario,
              "tramite": tramite,
              "estado": estado,
              "pago": tipoPago,
              "status": status,
              "nombre": nameform,
              "dependencia": dependencia,
              "fecha_inicio": startDate,
              "fecha_fin": finalDate,
              "captcha": captcha,
              "tipo": tipo_forma
            }
          };

          console.log("bodyForm BEFORE => ", bodyForm);
          console.log("model ? ", this.get("model"));
          // var parentController = controller.get('controllers.formularios/index');


          // BAD CODE this should be normalized to the formulario model but the
          // backend has to be updated so this can work properly
          //
          // TODO remove parentController deprecated
          //
          // console.log('parentController ===> ', parentController);
          Ember.$.ajax({
            //  url: config.ENV.APP.REST_WSPREFIX + ENV.APP.REST_WSSUFIXFORM,
            url: _environment.default.APP.REST_WSPREFIX + "/" + _environment.default.APP.WSSUFIX + '/formularios/' + formulario.get('id'),
            type: 'PUT',
            dataType: 'json',
            contentType: 'application/json',
            data: JSON.stringify(bodyForm)
          }).done(function (result) {
            var errorResult = JSON.stringify(result.error);

            console.log("result ==> ", result);

            if (errorResult !== undefined) {
              controller.set('isSaved', false);
              controller.set('txEvent', true);
              controller.set('txMessage', result.error);
              setTimeout(function () {
                if (controller !== null) {
                  controller.set('txEvent', false);
                  controller.set('txMessage', '');
                  // localStorage.setItem('JsonForm',"");
                }
              }, 6000);
            } else {
              controller.set('isSaved', false);
              // parentController.set('txEvent',true);
              // parentController.set('txMessage','Formulario actualizado exitosamente ');
              /* setTimeout(function () {
                if(parentController != null || parentController != undefined) {
                  console.log("PARENT =>> ", parentController);
                  parentController.set('txEvent', false);
                  parentController.set('txMessage', '');
                }
              },8000);*/
              localStorage.setItem('JsonForm', "");
              controller.transitionToRoute('/formularios');
              console.log("error!!!");
            }

            var idForm = formulario.get('id');
            var lock = false;
            var bodyForm = { "id": idForm, "lock": lock };
            Ember.$.ajax({
              //  url: config.ENV.APP.REST_WSPREFIX + ENV.APP.REST_WSSUFIXFORM,
              url: _environment.default.APP.REST_WSPREFIX + "/" + _environment.default.APP.WSSUFIX + '/locks/' + parseInt(idForm),
              type: 'PUT',
              dataType: 'json',
              contentType: 'application/json',
              data: JSON.stringify(bodyForm)
            }).done(function () {
              if (lock === false) {
                Ember.$(".fa.fa-lock." + parseInt(idForm)).css("color", "#008000");
              } else {
                Ember.$(".fa.fa-lock." + parseInt(idForm)).css("color", "#FF0000");
              }
            });
          }).fail(function (result) {
            controller.set('txEvent', true);
            controller.set('txMessage', 'Error al Actualizar el formulario' + result);
            setTimeout(function () {
              if (controller !== null) {
                controller.set('txEvent', false);
                controller.set('txMessage', '');
              }
            }, 5000);

            localStorage.setItem('JsonForm', "");
            controller.transitionToRoute('/formularios');
          });
        } else {
          Ember.$(".guardar").prop("disabled", false);
          Ember.$(".publicar").prop("disabled", false);
          Ember.$("#loading").css("display", "none");
          setTimeout(function () {
            if (controller !== null) {
              controller.set('txEvent', false);
              controller.set('message', '');
            }
          }, 5000);
        }
      },
      createFormulario: function createFormulario(status) {
        var rv = -1; // Return value assumes failure.
        var explorer = rv;
        var controller = this;
        var id_estado = null;
        var tipo_forma = false;
        var estado = 9; //JSON.stringify(controller.get('id_estado'));

        this.set('isUpdated', false);

        // If no session cancel
        if (!this.get('credentials')) {
          this.set('txMessage', 'Tu sesión ha expirado, ingresa e intenta de nuevo');
          this.set('isSaved', false);
          this.set('txEvent', true);

          return;
        }

        if (!this.rulesFormulario()) {
          this.set('txMessage', 'Tu sesión ha expirado, ingresa e intenta de nuevo');
          this.set('isSaved', false);
          this.set('txEvent', true);

          return;
        }

        // plantillas = JSON.stringify(controller.get('plantillasGet'));
        // localStorage.setItem('plantillasLS', plantillas);

        if (this.get('admin')) {
          // id_tipo_pago = controller.get('pago');
          id_estado = this.get('isSelectedEstados') === true ? this.get('selectedEstado') : this.get("store").find("estado", estado);
          // dependency = controller.get('dependencia');
          // tramiteid = 1;
        } // else {
        // if (!this.get('isSelectedDependencias')) {
        // tramiteid = 2;
        // }
        // dependency = controller.get('dependencia');
        // }
        // let newRecord = this.get("store").createRecord("formulario");

        var newRecord = this.get("store").createRecord("formulario", {
          nombre: this.get('nameform'),
          usuario: this.get("usuario"),
          estado: id_estado,
          tramite: this.get('tramite'),
          pago: this.get('pago'),
          status: status,
          dependencia: this.get('dependencia'),
          fecha_inicio: this.get('startdate'),
          fecha_fin: this.get('finaldate'),
          captcha: this.get('captcha'),
          // id_tipo_pago: this.get("tipoForm").get("id"),
          tipo: this.get('tipo'),
          lock: true
        });

        console.log("BEFORE SAVE ===> ", newRecord);
        newRecord.save().then(function (formulario) {
          console.log('AFTER SAVE ==> ', formulario);
          var idformulario = formulario.get('id');

          if (idformulario !== null) {
            controller.set('newFormulario', formulario);
            controller.set('isSaved', true);
            controller.set('id_form', idformulario);
            localStorage.setItem('idform_activado', parseInt(formulario.get('id')));
            // Ember.$(".panel-white :input").prop("disabled", true); // bloquear eelementos
          } else {
            controller.set('txMessage', 'El nombre de la encuesta que quieres crear ya existe. Por favor modifícalo para poder continuar.');
            controller.set('isSaved', false);
            controller.set('txEvent', true);
            setTimeout(function () {
              if (controller !== null) {
                controller.set('txEvent', false);
                controller.set('message', '');
              }
            }, 10000);
          }
        });
      },

      saveFormularioAtt: function saveFormularioAtt(status) {
        Ember.$(".guardar").prop("disabled", true);
        Ember.$(".publicar").prop("disabled", true);
        Ember.$("#loading").css("display", "block");

        var controller = this;
        var flag = false;
        var data = localStorage.getItem('JsonForm');
        var obj = null;
        var att_name = "";
        if (data) {
          obj = JSON.parse(data);
        }

        var faltantes = 0;
        var faltaName = false;
        var numElem = 0;
        var esEstepper = null;
        var stepper_num = null;
        var estepperNoAlign = 0;
        var out_of_stepper = false;

        Ember.$.each(obj.atributos, function (i) {
          console.log('elementos a guardar  nuevo elemento');
          numElem = i;

          if (obj.atributos[i].type === 'header') {
            if (obj.atributos[i].element === 'Stepper') {
              esEstepper = i;
              stepper_num = stepper_num + 1;
            }
          }

          if (esEstepper > 0 && numElem > 0 && stepper_num === 1) {
            estepperNoAlign = 1;
          }

          if (estepperNoAlign === 1) {
            controller.set('txMessage', "Tienes elementos arriba de la primera sección " + obj.atributos[i].label + " . Por favor desciéndelos. para continuar");
            controller.set('txEvent', true);
            faltantes += 1;
            out_of_stepper = true;
          }

          if (obj.atributos[i].type !== 'hr') {
            if (obj.atributos[i].name === undefined) {
              faltantes += 1;

              if (obj.atributos[i].type === 'header') {
                controller.set('txMessage', "Existe un elemento " + obj.atributos[i].element + " que no tienen un nombre asignado. Por favor asignelos. para continuar");
              } else {
                controller.set('txMessage', "Existe un elemento " + obj.atributos[i].type + " que no tienen un nombre asignado. Por favor asignelos. para continuar");
              }

              controller.set('txEvent', true);
            }

            if (obj.atributos[i].type === 'button') {
              if (obj.atributos[i].url_button === undefined) {
                faltantes += 1;

                controller.set('txMessage', "Existe un elemento " + obj.atributos[i].type + " que no tiene asignado una url. Por favor asignelos. para continuar");
                controller.set('txEvent', true);
              }

              if (obj.atributos[i].label === undefined) {
                faltantes += 1;

                controller.set('txMessage', "Existe un elemento " + obj.atributos[i].type + " que no tiene asignado una etiqueta. Por favor asignelos. para continuar");
                controller.set('txEvent', true);
              }
            }
          }

          if (obj.atributos[i].name !== undefined) {
            att_name += "," + obj.atributos[i].name;
          }
        });

        var uniqueList = att_name.split(',').filter(function (allItems, i, a) {
          return i === a.indexOf(allItems);
        }).join(',');

        if (uniqueList !== att_name) {
          controller.set('txMessage', 'Existen campos con el mismo nombre  dentro del formulario');
          controller.set('txEvent', true);
          flag = true;
        }

        if (faltantes === 0) {
          faltaName = false;
        } else {
          faltaName = true;
        }

        var numElements = 0;
        var thereAreElementsGroup = false;
        var faltaElementoAgrupado = 0;
        var tipo_forma = false;

        Ember.$.each(obj.atributos, function (i) {
          if (obj.atributos[i].type === 'header') {
            if (obj.atributos[i].element === 'Stepper') {
              tipo_forma = true;
            }
          } else if (obj.atributos[i].type === 'elementsGroup') {
            thereAreElementsGroup = true;
            if (obj.atributos[i].elements !== undefined) {
              numElements = obj.atributos[i].elements.length;
              if (numElements === undefined) {
                numElements = 1;
              }
            } else {
              numElements = 0;
            }
          }

          if (thereAreElementsGroup) {
            if (numElements < 2) {
              faltaElementoAgrupado += 1;
              controller.set('txMessage', 'El grupo de elementos debe contar con 2 o más elementos, por favor agrégalos para continuar.');
              controller.set('txEvent', true);
              // (etiqueta)
            }
          }
        });

        var faltaEA = true;

        if (faltaElementoAgrupado === 0) {
          faltaEA = false;
        } else {
          faltaEA = true;
        }

        if (data !== null && data !== undefined && data !== "" && flag === false && faltaName === false && faltaEA === false && out_of_stepper === false) {
          if (this.get('isSelectedEstados') === true) {
            var id_estado = controller.get('selectedEstado');
            controller.get('newFormulario').set('estado', id_estado);
          }

          var id_tramite = controller.get('selectedTramite');
          var id_tipo_pago = controller.get('selectedTipoPago');

          controller.get('newFormulario').set('tramite', id_tramite);
          controller.get('newFormulario').set('pago', id_tipo_pago);

          var atributos = JSON.parse(data);
          var bodyForm = { "id_formulario": this.get("newFormulario").get('id'), "status": status, "tipo": tipo_forma, "atributos": atributos.atributos };

          var parentController = controller.get('controllers.formularios/index');
          Ember.$.ajax({
            //url: config.ENV.APP.REST_WSPREFIX + ENV.APP.REST_WSSUFIXFORM,
            url: _environment.default.APP.REST_WSPREFIX + "/" + _environment.default.APP.WSSUFIX + '/atributos',
            type: 'POST',
            dataType: 'json',
            contentType: 'application/json',
            data: JSON.stringify(bodyForm)
          }).done(function () {
            if (controller.get('isUpdated') === true) {
              var formUpdate = { "id": controller.get("newFormulario").get('id'), "status": status, "tipo": tipo_forma, "atributos": atributos.atributos, "formulario": controller.get("newFormulario") };

              Ember.$.ajax({
                url: _environment.default.APP.REST_WSPREFIX + _environment.default.APP.WSSUFIX + 'formularios/' + controller.get("newFormulario").get('id'),
                type: 'PUT',
                dataType: 'json',
                contentType: 'application/json',
                data: JSON.stringify(formUpdate)
              }).done(function () {
                var parentController = controller.get('controllers.formularios/index');
                controller.set('isUpdated', false);
                controller.set('isSaved', false);
                controller.set('selectedEstado', null);
                controller.set('selectedTramite', null);
                controller.set('selectedTipoPago', null);
                controller.set('nameform', null);
                controller.set('startdate', null);
                controller.set('finaldate', null);
                controller.set('plantillas', null);
                parentController.set('txEvent', true);
                parentController.set('txMessage', 'Los datos fueron guardados exitosamente');
                setTimeout(function () {
                  if (parentController !== null) {
                    parentController.set('txEvent', false);
                    parentController.set('txMessage', '');
                  }
                }, 8000);

                localStorage.setItem('JsonForm', "");
                controller.transitionToRoute('/formularios');
              });
            } else {
              controller.set('isUpdated', false);
              controller.set('isSaved', false);
              controller.set('selectedEstado', null);
              controller.set('selectedTramite', null);
              controller.set('selectedTipoPago', null);
              controller.set('nameform', null);
              controller.set('startdate', null);
              controller.set('finaldate', null);
              controller.set('plantillas', null);
              parentController.set('txEvent', true);
              parentController.set('txMessage', 'Los datos fueron guardados exitosamente');
              setTimeout(function () {
                if (parentController !== null) {
                  parentController.set('txEvent', false);
                  parentController.set('txMessage', '');
                }
              }, 8000);

              localStorage.setItem('JsonForm', "");
              controller.transitionToRoute('/formularios');
            }
          }).fail(function () {
            parentController.set('txEvent', true);
            parentController.set('txMessage', 'Ocurrio un error  al guardar los datos');
            setTimeout(function () {
              if (parentController !== null) {
                parentController.set('txEvent', false);
                parentController.set('txMessage', '');
              }
            }, 5000);

            localStorage.setItem('JsonForm', "");
            controller.transitionToRoute('/formularios');
          });
        } else {
          Ember.$(".guardar").prop("disabled", false);
          Ember.$(".publicar").prop("disabled", false);
          Ember.$("#loading").css("display", "none");
          setTimeout(function () {
            if (controller !== null) {
              controller.set('txEvent', false);
              controller.set('message', '');
            }
          }, 5000);
        }
      },
      cancelyElim_Formulario: function cancelyElim_Formulario() {
        var controller = this;
        this.set('isSaved', false);
        var fromId = Ember.$.trim(controller.get('id_form').replace(/"/g, ""));
        var parentController = this.get('controllers.formularios/index');
        Ember.$.ajax({
          url: _environment.default.APP.REST_WSPREFIX + _environment.default.APP.WSSUFIX + 'formularios/' + fromId,
          type: 'DELETE',
          dataType: 'json',
          async: false
        }).done(function () {});

        parentController.set('txEvent', true);
        parentController.set('txMessage', 'Operación cancelada');
        this.transitionToRoute('/formularios');

        setTimeout(function () {
          if (parentController !== null) {
            parentController.set('txEvent', false);
            parentController.set('txMessage', '');
          }
        }, 7000);

        this.set('isUpdated', false);
        this.set('isSaved', false);
        this.set('selectedEstado', null);
        this.set('selectedTramite', null);
        this.set('selectedTipoPago', null);
      },
      cancelFormulario: function cancelFormulario(model, idform) {
        console.log('cancelado de formulario');
        var lock = false;
        var bodyForm = { "id": idform, "lock": lock };
        Ember.$.ajax({
          //  url: config.ENV.APP.REST_WSPREFIX + ENV.APP.REST_WSSUFIXFORM,
          url: _environment.default.APP.REST_WSPREFIX + "/" + _environment.default.APP.WSSUFIX + '/locks/' + idform,
          type: 'PUT',
          dataType: 'json',
          contentType: 'application/json',
          data: JSON.stringify(bodyForm)
        }).done(function () {
          if (lock === false) {
            Ember.$(".fa.fa-lock." + idform).css("color", "#008000");
          } else {
            Ember.$(".fa.fa-lock." + idform).css("color", "#FF0000");
          }
        });

        this.set('isSaved', false);

        var parentController = this.get('controllers.formularios/index');
        parentController.set('txEvent', true);
        parentController.set('txMessage', 'Operación cancelada');

        setTimeout(function () {
          if (parentController !== null) {
            parentController.set('txEvent', false);
            parentController.set('txMessage', '');
          }
        }, 7000);

        this.set('selectedEstado', null);
        this.set('selectedTramite', null);
        this.set('selectedTipoPago', null);
        this.transitionToRoute('/formularios');

        this.set('isUpdated', false);
        this.set('isSaved', false);
      },
      regresarFormulario: function regresarFormulario() {
        this.transitionToRoute('/formularios');
      }
    }
  });
});