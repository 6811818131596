define('dynforms-client/models/atributo', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var atributoModel = _emberData.default.Model.extend({
    idAtributo: _emberData.default.attr('number'),
    atributo: _emberData.default.attr('string'),
    valor: _emberData.default.attr('number'),
    formAtts: _emberData.default.hasMany('formAtts')
  });

  exports.default = atributoModel;
});