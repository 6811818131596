define('dynforms-client/models/form_attx', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var formAttx = _emberData.default.Model.extend({
    class: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    label: _emberData.default.attr('string'),
    maxlength: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    placeholder: _emberData.default.attr('string'),
    required: _emberData.default.attr('string'),
    type: _emberData.default.attr('string'),
    subtype: _emberData.default.attr('string'),
    formulario: _emberData.default.belongsTo('formulario', { async: false })

  });
  exports.default = formAttx;
});