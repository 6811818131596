define('dynforms-client/helpers/is-lock', ['exports', 'dynforms-client/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isLock = isLock;
  function isLock(lock) {

    var class_ = "";
    if (lock === "true") {
      class_ = "style='color:red;'";
    } else {
      class_ = "style='color:#52BE80;'";
    }

    return class_;
  }
  //  export default Ember.Handlebars.makeBoundHelper(isLock);
  exports.default = Ember.Helper.helper(isLock);
});