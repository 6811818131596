define('dynforms-client/components/lists/item-formulario', ['exports', 'dynforms-client/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    attributeBindings: ['item'],
    router: Ember.inject.service(),
    dependencia: Ember.computed("item", function () {
      console.log("item ==> ", this.get("item"));

      return true;
    }),
    actions: {
      goToPreview: function goToPreview(params) {
        this.router.transitionTo('/formularios/show/' + params.id);
        this.set('changeView', true);
      },
      goToFormulario: function goToFormulario(params) {
        this.set('filterStatus', '');
        this.set('changeView', true);
        this.router.transitionTo('/formularios/edit/' + params.id);
      },
      goToReports: function goToReports() {
        this.router.transitionTo('/formularios/reports');
      },
      createNewFormulario: function createNewFormulario() {
        this.set('filterStatus', '');
        this.set('changeView', false);
        this.router.transitionTo('/formularios/new');
      },
      deleteFormulario: function deleteFormulario(params) {
        var controller = this;
        var value = "[" + JSON.stringify(params) + "]";
        var itemSelected = params;

        value = JSON.parse(value);
        this.set('changeView', false);
        itemSelected.destroyRecord();

        controller.set('txEvent', true);
        controller.set('txMessage', 'Formulario eliminado exitosamente');

        /*Ember.$.prompt("¿Está usted seguro de eliminar el formulario? " + "<b>"+ value[0].nombre+ "</b>" , {
          overlayspeed:'fast',
          promptspeed:'fast',
          title: "Formulario",
          buttons: { "Eliminar": true, "Cancelar": false },
          submit: function(e,answer){
          if(answer === true){*/
      },
      toggleLock: function toggleLock(item) {
        var controller = this;
        var bodyForm = {
          "id": item.get("id"),
          "lock": item.get("lock") ? false : true
        };

        console.log("bodyForm => ", bodyForm);

        Ember.$.ajax({
          url: _environment.default.APP.REST_WSPREFIX + "/" + _environment.default.APP.WSSUFIX + '/locks/' + item.get("id"),
          type: 'PUT',
          dataType: 'json',
          contentType: 'application/json',
          data: JSON.stringify(bodyForm)
        }).done(function (result) {
          controller.get("item").set("lock", bodyForm.lock);
          // msgfinal = JSON.stringify(result.resultado);
          // botones =  {  "Aceptar": true };
        });
      }
    }
  });
});