define('dynforms-client/components/view-reporte', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    viewreporte: false,
    attributeBindings: ['estado'],
    didInsertElement: function didInsertElement() {
      var controller = this,
          result = null;
      var estado = this.get('estado');
      // console.log ("esatdo ---->"+estado);
      if (estado !== undefined && estado !== null) {
        result = false;
      } else {
        result = true;
      }
      // console.log (result);
      controller.set('viewreporte', result);
    }
  });
});