define('dynforms-client/models/estado', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var estadoModel = _emberData.default.Model.extend({
		estado: _emberData.default.attr('string'),
		formularios: _emberData.default.hasMany('formulario', { async: true }),
		id_usuario: _emberData.default.belongsTo('user', { async: true })
	});
	exports.default = estadoModel;
});