define('dynforms-client/routes/formularios/edit', ['exports', 'dynforms-client/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    session: Ember.inject.service(),
    isedit: true,
    admin: false,
    btnPublicar: false,
    idform: true,
    esEstado: false,
    viewTipoForm: false,
    editandoForm: false,
    tramiteactive: false,
    activaBottons: false,
    padlock: false,
    model: function model(params) {
      console.log('parrams ==> ', params);

      this.set('idform', params.id_formulario);
      return this.store.find('formulario', params.id_formulario);
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      if (localStorage[_environment.default.APP.LS] === undefined) {
        localStorage.setItem('isUserActive', false);

        return;
      }

      var localItem = JSON.parse(localStorage[_environment.default.APP.LS]); // ALL object
      var idLocalStorange = localStorage.getItem('idform_activado');
      var isAuthenticated = localStorage !== null ? localStorage.getItem(_environment.default.APP.LS) : null;

      // IF not logged in then finish
      if (isAuthenticated === null) {
        localStorage.setItem('isUserActive', false);

        return;
      }

      this.session.validateSession(localItem.json.user_id, localItem.json.access_token).then(function (response) {
        controller.set('credentials', response);
        controller.set('isLogged', true);
      });

      /*-----------------------------------------------------------------------------*/

      var id_user = null;
      var JSONselectedEstado = null;
      var JSONselectedTramite = null;
      var JSONselectedTipoPago = null;
      var JSONselectedDependencia = null;
      var JSONNombre = null;
      var JSONStartDate = null;
      var JSONFinalDate = null;
      var JSONTipo = null;

      var isSelectedEstado = null;
      var isSelectedDependencia = null;
      var estatus = null;
      var captcha = null;
      var lock = null;
      var lockjs = null;
      var bodyForm = null;

      Ember.$.ajax({
        url: _environment.default.APP.REST_WSPREFIX + "/" + _environment.default.APP.WSSUFIX + '/formularios/' + this.get('idform'),
        type: 'GET',
        dataType: 'json',
        async: false
      }).done(function (jsonObject) {
        JSONselectedEstado = jsonObject.formulario.estado;
        JSONselectedTramite = jsonObject.formulario.tramite;
        JSONselectedTipoPago = jsonObject.formulario.pago;
        JSONselectedDependencia = jsonObject.formulario.dependencia;
        JSONNombre = jsonObject.formulario.nombre;
        JSONStartDate = jsonObject.formulario.fecha_inicio;
        JSONFinalDate = jsonObject.formulario.fecha_fin;
        JSONTipo = jsonObject.formulario.tipo;
        estatus = jsonObject.formulario.estatus;
        captcha = jsonObject.formulario.captcha;
        lockjs = jsonObject.formulario.lock;
        id_user = jsonObject.formulario.usuario.id;
      });

      // let idLocalStorange = localStorage.getItem('idform_activado');
      var id = this.get('idform');
      /*Si el formulario en el local storange es diferente al que se esta abriendo*/
      if (idLocalStorange !== this.get('idform')) {
        /*Si es diferente y esta bloqueado con anterioridad*/
        if (lockjs === false || lockjs === null) {
          bodyForm = { "id": id, "lock": true };

          Ember.$.ajax({
            //  url: config.ENV.APP.REST_WSPREFIX + ENV.APP.REST_WSSUFIXFORM,
            url: _environment.default.APP.REST_WSPREFIX + "/" + _environment.default.APP.WSSUFIX + '/locks/' + id,
            type: 'PUT',
            dataType: 'json',
            contentType: 'application/json',
            data: JSON.stringify(bodyForm)
          }).done(function () {
            Ember.$(".fa.fa-lock" + id).css("color", lock === false ? "#008000" : "#FF0000");
          });
          if (idLocalStorange !== null && idLocalStorange !== 'null') {
            lock = false;
            bodyForm = { "id": idLocalStorange, "lock": lock };

            Ember.$.ajax({
              url: _environment.default.APP.REST_WSPREFIX + "/" + _environment.default.APP.WSSUFIX + '/locks/' + id,
              type: 'PUT',
              dataType: 'json',
              contentType: 'application/json',
              data: JSON.stringify(bodyForm)
            }).done(function () {});
          }

          lock = false;
          Ember.$(".fa.fa-lock" + id).css("color", "#008000");
          localStorage.setItem('idform_activado', id);
        } else {
          lock = true;
          Ember.$(".fa.fa-lock" + id).css("color", "#FF0000");
        }
      } else {
        localStorage.setItem('idform_activado', id);
        lock = false;
        Ember.$(".fa.fa-lock" + id).css("color", "#008000");
      }

      var fecha_actual = new Date();
      var valor = null;

      if (lock === null) {
        lock = false;
        Ember.$(".fa.fa-lock." + id).css("color", "#008000");
        localStorage.setItem('idform_activado', id);
      }

      controller.set('padlock', lock);
      /* Meses en explorer */
      var mesesString = "";
      for (var i = 1; i <= 12; i++) {
        if (i === 1) {
          mesesString = mesesString + '{"id":"' + i + '","text":"0' + i + '","description":"0' + i + '"}';
        } else {
          valor = "" + i;
          if (i < 10) {
            valor = "0" + i;
          }
          mesesString = mesesString + ',{"id":"' + i + '","text":"' + valor + '","description":"' + valor + '"}';
        }
      }
      /* Dias Explorer*/
      var meses = JSON.parse("[" + mesesString.toString() + "]");
      var diasString = "";
      for (var j = 1; j <= 31; j++) {
        if (j === 1) {
          diasString = diasString + '{"id":"' + j + '","text":"0' + j + '","description": "0' + j + '"}';
        } else {
          valor = j;
          if (j < 10) {
            valor = "0" + j;
          }
          diasString = diasString + ',{"id":"' + j + '","text":"' + valor + '","description":"' + valor + '"}';
        }
      }
      var dias = JSON.parse("[" + diasString.toString() + "]");
      /*
      Anio Explorer*/
      var anioString = "";
      for (var k = 1; k <= 5; k++) {
        valor = 2016 + k;
        if (k === 1) {
          anioString = anioString + '{"id":"' + valor + '","text":"' + valor + '","description":"' + valor + '"}';
        } else {
          anioString = anioString + ',{"id":"' + valor + '","text":"' + valor + '","description":"' + valor + '"}';
        }
      }

      var anios = JSON.parse("[" + anioString.toString() + "]");

      /*Vaciado alos controleres*/
      controller.set("dias", dias);
      controller.set("meses", meses);
      controller.set("anios", anios);

      if (fecha_inicial !== undefined && fecha_inicial !== null && fecha_inicial !== "" && fecha_final !== undefined && fecha_final !== null && fecha_final !== "") {
        var fecha_inicial = new Date(JSONStartDate);
        var fecha_final = new Date(JSONFinalDate);

        //calcula las fechas si es menor a la fecha actual y el estatus es  2 es que esta en borrador
        if (fecha_actual.getTime() < fecha_inicial.getTime() && estatus === 2) {
          controller.set('activaBottons', true);
        } else if (fecha_actual.getTime() > fecha_inicial.getTime() && fecha_actual.getTime() < fecha_final.getTime()) {
          controller.set('activaBottons', false);
        } else {
          controller.set('activaBottons', true);
        }
      } else {
        controller.set('activaBottons', true);
      }

      // let localItem = JSON.parse(localStorage[config.APP.LS]);
      var id_estado = localItem.json.estado;
      var role = localItem.json.role;

      controller.set('isSelectedEstados', localItem.json.estado !== null ? false : true);

      /* Validacion de si aparece el boton de publicar*/
      if (role === "SA") {
        controller.set('btnPublicar', true);
        controller.set('tramiteactive', true);
      } else if (role === "AD" || role === "EAD" || role === "ED" || role === "EED") {
        if (role === 'ED' || role === 'AD') {
          controller.set('admin', true);
          controller.set('viewTipoForm', false);
          controller.set('tramiteactive', true);
        } else if (role === 'EED' || role === 'EAD') {
          controller.set('admin', false);
          controller.set('viewTipoForm', false);
          controller.set('tramiteactive', true);
        }

        controller.set('btnPublicar', false);
      }

      controller.set('editandoForm', true);
      // LLena los catalogos
      controller.set('estados', this.store.findAll('estado'));
      controller.set('pagos', this.store.findAll('pago'));
      controller.set('tramites', this.store.findAll('tramite'));
      controller.set('dependencias', this.store.findAll('dependency'));

      // TODO replace the json w/relationships
      controller.set('estado', JSONselectedEstado);
      controller.set('tramite', JSONselectedTramite);
      controller.set('pago', JSONselectedTipoPago);
      controller.set('dependencia', JSONselectedDependencia);
      controller.set('nameform', JSONNombre);
      controller.set('startdate', JSONStartDate);
      controller.set('finaldate', JSONFinalDate);
      controller.set('tipo', JSONTipo);
      controller.set('estatus', estatus);
      controller.set('captcha', captcha);

      if (localItem.json.dependencia !== null) {
        if (JSONStartDate) {
          var dateInicial = JSONStartDate.split('-');
          var dateFinal = JSONFinalDate.split('-');

          controller.set('initialDia', { "id": parseInt(dateInicial[2]), "description": dateInicial[2] });
          controller.set('initialMes', { "id": parseInt(dateInicial[1]), "description": dateInicial[1] });
          controller.set('initialAnio', { "id": parseInt(dateInicial[0]), "description": dateInicial[0] });
          controller.set('finalDia', { "id": parseInt(dateFinal[2]), "description": dateFinal[2] });
          controller.set('finalMes', { "id": parseInt(dateFinal[1]), "description": dateFinal[1] });
          controller.set('finalAnio', { "id": parseInt(dateFinal[0]), "description": dateFinal[0] });
        }
      }

      controller.set('isedit', true);
      controller.set('id_user', id_user);
      controller.set('id_estado', id_estado);

      if (role === 'SA') {
        if (JSONselectedEstado === undefined) {
          isSelectedEstado = false;
          isSelectedDependencia = true;
          controller.set('admin', false);
        } else {
          isSelectedEstado = true;
          isSelectedDependencia = false;
          controller.set('admin', true);
        }
      } else {
        isSelectedEstado = localItem.json.estado !== null ? false : true;
        isSelectedDependencia = localItem.json.dependencia !== null ? false : true;
      }

      controller.set('typeExplorer', localStorage.getItem('typeExplorer'));
      controller.set('isSelectedEstados', isSelectedEstado);
      controller.set('isSelectedDependencias', isSelectedDependencia);
      controller.set('plantillaView', false);

      /* se agrega*/
      controller.set('tipoForm', [{ id: 1, text: 'Formulario', description: 'Forma' }, { id: 2, text: 'Encuesta', description: 'Encuesta' }]);
      // estatus de formulario utilizado para el bloqueo del formulario si esta publicado o no
      //localStorage.setItem('statusForm',estatus);
      // se realizo el cambio pero el issue fue cancelado por eso se le envia siempre que este activo y
      // se  visualicen todos los campos cuando se edita.
      localStorage.setItem('statusForm', 2);
    }
  });
});