define('dynforms-client/controllers/formularios/reports', ['exports', 'dynforms-client/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    filter: '',
    namesSearch: '',
    status: '',
    changeView: false,
    loading: true,
    filteredContent: function () {
      var filterStatus = this.get('status');
      var filter = this.get('filter');
      var changeView = this.get('changeView');
      var getRol = this.get('newUserInstance.role.text');
      // console.log(filterStatus);

      if (filterStatus === 1) {
        filterStatus = true;
      } else if (filterStatus === 2) {
        filterStatus = false;
      } else {
        filterStatus = 0;
      }

      if (filterStatus !== null || filterStatus !== "") {
        var rxS = new RegExp(filterStatus, 'gi');
      }

      if (getRol === undefined) {
        getRol = this.get('user.selectedUserRol.text');
      }

      if (getRol === 'ED' || getRol === 'AD') {
        this.set('rolActive', true);
        this.set('rolView', true);
      } else if (getRol === 'EED' || getRol === 'EAD') {
        this.set('rolActive', false);
        this.set('rolView', true);
      } else {
        this.set('rolView', false);
      }

      var rx = new RegExp(filter, 'gi');
      var reportes = this.get('content');

      if (filter.length > 0) {
        return reportes.filter(function (reporte) {
          return reporte.get('url').match(rx) || reporte.get('id_dependencia').match(rx);
        });
      } else if (filterStatus.length > 0 || filterStatus !== null || filterStatus !== "") {
        return reportes.filter(function (reporte) {
          var response = true;

          if (filterStatus != null && filterStatus !== undefined && filterStatus !== "" && response) {
            if (filterStatus !== 0) {
              if (changeView === true) {
                response = reporte.get('vigente').match(rxS);
              }
            }
          }

          return response;
        });
      } else {
        return reportes.filter(function (reporte) {
          return reporte.id !== undefined ? true : false;
        });
      }
    }.property('content', 'filter', 'content.@each', 'status', 'changeView'),
    actions: {
      changeStatus: function changeStatus(status) {
        Ember.$(".navStatus").removeClass("active");
        Ember.$("#status-" + status).addClass("active");
        this.set('status', status);
        this.set('changeView', true);

        return false;
      },
      goToReportes: function goToReportes(nombreArchivo, id_dependencia) {
        var url = _environment.default.APP.LinkReport + "id_dependencia=" + id_dependencia + "&nombre=" + nombreArchivo;
        window.location = url;
      }
    }
  });
});