define('dynforms-client/controllers/application', ['exports', 'dynforms-client/config/environment'], function (exports, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var applicationController = Ember.Controller.extend({
		needs: ['login'],
		queryParams: ["code"],
		isLogged: false,
		isSelectedEstado: false,
		esSuperAdmin: false,
		linkReporte: _environment.default.APP.LinkReport,
		actions: {
			goToHome: function goToHome() {
				this.send('classActive');
				this.transitionToRoute('/');
			},
			goToFormulario: function goToFormulario() {
				this.transitionToRoute('/formularios');
			},
			createNewFormulario: function createNewFormulario() {
				this.transitionToRoute('/formularios');
			},
			goToPreview: function goToPreview() {
				this.transitionToRoute('/formularios');
			},
			goToUsers: function goToUsers() {
				this.transitionToRoute('/users');
			},
			goToReports: function goToReports() {
				// alert ('aplicacion');
				this.transitionToRoute('/formularios/reports');
			},
			logout: function logout() {
				var controller = this;
				var localItem = JSON.parse(localStorage[_environment.default.APP.LS]);
				localStorage.setItem('href', _environment.default.APP.REST_WSPREFIX + "/" + _environment.default.APP.WSSUFIX);
				var token = localItem.json.access_token;
				var query = 'token=' + token;

				Ember.$.ajax({
					url: _environment.default.APP.REST_WSPREFIX + "/" + _environment.default.APP.WSSUFIX + '/sessions/sign_out?' + query,
					type: 'POST'
				}).then(function () {
					delete window.localStorage[_environment.default.APP.LS];
					controller.set('id_usuario', null);
					// controller.transitionToRoute('/login');
					controller.set('isLogged', false);
					controller.set('isSelectedEstado', false);
					controller.set('esSuperAdmin', false);
				});
			},
			classActive: function classActive() {}
		}
	});
	exports.default = applicationController;
});