define('dynforms-client/models/tramite', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var tramiteModel = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    dependence: _emberData.default.attr('number'),
    description: _emberData.default.attr('string'),
    cntseId: _emberData.default.attr('string'),
    presencial: _emberData.default.attr('string'),
    costNote: _emberData.default.attr('string'),
    documentNote: _emberData.default.attr('string'),
    status: _emberData.default.attr('number'),
    created_at: _emberData.default.attr('date'),
    subcats: _emberData.default.attr('string'),
    legend: _emberData.default.attr('string'),
    show_legend: _emberData.default.attr('boolean'),
    actionTypes: _emberData.default.hasMany('actionType', { embedded: 'always' }),
    documents: _emberData.default.hasMany('document', { embedded: 'always', async: false }),
    documentsGroup: _emberData.default.hasMany('documentsGroup', { embedded: 'always', async: false }),
    costs: _emberData.default.hasMany('cost', { embedded: 'always' }),
    formularios: _emberData.default.hasMany('formulario', { async: true, inverse: 'tramite' }),

    formattedCostNote: function () {
      return this.get('costNote') !== null ? this.get('costNote') : '';
    }.property('costNote'),
    formattedDocumentNote: function () {
      return this.get('documentNote') !== null ? this.get('documentNote') : '';
    }.property('documentNote'),
    formattedOpcionesTramite: function () {
      return this.get('presencial') !== null ? this.get('presencial') : '';
    }.property('presencial'),
    isPublic: function () {
      return this.get('status') !== null && this.get('status') === 2 ? true : false;
    }.property('status')

  });

  exports.default = tramiteModel;
});