define('dynforms-client/helpers/validate-date-explorer', ['exports', 'dynforms-client/config/environment'], function (exports, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.validatedateexplorer = validatedateexplorer;
	function validatedateexplorer() {
		function validando() {

			var rv = -1;
			if (navigator.appName === 'Microsoft Internet Explorer') {
				var ua = navigator.userAgent,
				    re = new RegExp("MSIE ([0-9]{1,}[\\.0-9]{0,})");
				if (re.exec(ua) !== null) {
					rv = parseFloat(RegExp.$1);
				}
			} else if (navigator.appName === "Netscape") {
				if (navigator.appVersion.indexOf('Trident') === -1) {
					rv = 12;
				} else {
					rv = 11;
				}
			}
			var explorer = rv;
			if (explorer === 12) {
				Ember.$(".dateie").addClass("hide");
				Ember.$(".date").addClass("block");
			} else {
				Ember.$(".date").addClass("hide");
				Ember.$(".dateie").addClass("block");
			}
		}
		setTimeout(validando, 100);
	}
	// export default Ember.Handlebars.makeBoundHelper(validatedateexplorer);
	exports.default = Ember.Helper.helper(validatedateexplorer);
});