define('dynforms-client/models/document', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var documentModel = _emberData.default.Model.extend({
    description: _emberData.default.attr('string'),
    linkformat: _emberData.default.attr('string'),
    original: _emberData.default.attr('boolean'),
    copies: _emberData.default.attr('number'),
    order: _emberData.default.attr('number'),
    formality: _emberData.default.belongsTo('tramite', { async: true }),
    groupId: _emberData.default.belongsTo('documentsGroup', { async: true }),
    certificate_copies: _emberData.default.attr('number'),
    original_copies: _emberData.default.attr('number'),
    isFormat: function () {
      return this.get('linkformat') !== undefined && this.get('linkformat') !== null && this.get('linkformat').trim().length > 0 ? true : false;
    }.property('linkformat'),
    formattedQuantity: function () {
      var original = this.get('original');
      var copies = this.get('copies');
      var strFormat = original ? 'Original' : '';
      strFormat += copies === 1 ? (original ? ' y ' : '') + 'Copia' : copies > 1 ? (original ? ' y ' : '') + copies + ' Copias' : '';
      return strFormat;
    }.property('original', 'copies')
  });

  exports.default = documentModel;
});