define('dynforms-client/helpers/class-active', ['exports', 'dynforms-client/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.classactive = classactive;
  function classactive(status) {
    var class_ = "";
    if (status === "3") {

      class_ = "style='color:#4D92DF;'";
    } else if (status === "2") {
      class_ = "style='color:#D0021B;'";
    } else {
      class_ = "style='color:#95A5A6;'";
    }
    Ember.$(this).addClass("disabled");
    return class_;
  }

  // export default Ember.Handlebars.makeBoundHelper(classactive);
  exports.default = Ember.Helper.helper(classactive);
});