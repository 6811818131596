define("dynforms-client/helpers/type-user", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.typeUser = typeUser;
  function typeUser(params /*, hash*/) {
    var valor = Ember.Handlebars.Utils.escapeExpression(params),
        type_user = "";

    if (valor === "EAD") {
      type_user = "EAD Administrador";
    } else if (valor === "EED") {
      type_user = "EED Editor";
    }

    if (valor === "SA") {
      type_user = "SA Super administrador";
    }

    return Ember.String.htmlSafe("\n    " + type_user + "\n  ");
  }

  exports.default = Ember.Helper.helper(typeUser);
});