define('dynforms-client/helpers/verify-session', ['exports', 'dynforms-client/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.verifysession = verifysession;
    function verifysession() {
        var isLogeado = localStorage.getItem('isUserActive');
        var num_prolongaciones = 0;
        // tiempos en produccion de 30 minutos y 5 minutos mas de tiempo extra
        var maxTime = 1800000;
        var extraTime = 300000;

        // tiempos de prueba en dev
        //var maxTime = 5000;  
        //var extraTime =6000; 

        var controller = this;

        var ctrlmsg = false;
        var controlmesage = false;
        var cerrar = null;
        function confirmarCierre() {
            //le doy un tiempo a la funcion cerrar sesion para que el usuario tenga un tiempo para confirmar, sino lo hizo en el tiempo se cerrara la sesion automaticamente
            //console.log('confirmar cierre');
            if (isLogeado === 'true') {
                cerrar = setTimeout(cerrarSesion, extraTime); //cierree
                // y volver a iniciarlo con 10segs
                var msg = "";

                Ember.$("#dialog-confirmar").dialog({
                    resizable: false,
                    height: "auto",
                    width: 600,
                    modal: true,
                    buttons: {
                        "Continuar": function Continuar() {
                            Ember.$(this).dialog("close");
                            num_prolongaciones = num_prolongaciones + 1;
                            //si presiona OK

                            //   console.log("num _prolongaciones --_>"+num_prolongaciones);
                            if (num_prolongaciones >= 6) {
                                console.log("cerrar session numero de prolongaciones a sido superado   ");
                                Ember.$(this).dialog("close");
                                ctrlmsg = true;
                                controlmesage = true;
                                clearTimeout(temp);
                                clearTimeout(cerrar);
                                msg = "El número de prolongaciones de sesión a llegado a su máximo. Por favor  vuelva a iniciar sesión";
                                cerrarSesion(msg);
                            } else {
                                clearTimeout(cerrar); //elimino el tiempo a la funcion cerrarSesion
                                //   console.log(cerrar);
                                clearTimeout(temp); //elimino el tiempo a la funcion confirmarCierre
                                // temp = setTimeout(confirmarCierre, 3600000); //y aca le doy un nuevo tiempo a la funcion confirmarCierre (5 segs)
                                temp = setTimeout(confirmarCierre, maxTime); //y aca le doy un nuevo tiempo a la funcion confirmarCierre (5 segs)
                                //   console.log("Prolonga sesion");
                                ctrlmsg = false;
                                controlmesage = false;
                            }
                        },
                        "Cerrar sesión": function CerrarSesiN() {
                            console.log("cerrar session");
                            Ember.$(this).dialog("close");
                            ctrlmsg = true;
                            controlmesage = true;
                            clearTimeout(temp);
                            clearTimeout(cerrar);
                            msg = "Su sesión a terminado. Por favor cierre el navegador por seguridad ";
                            cerrarSesion(msg);
                        }

                    }
                });
            }
        }
        function cerrarSesion(msg) {
            clearTimeout(temp);
            clearTimeout(cerrar);

            logout();
            if (ctrlmsg === true) {
                Ember.$("#dialog-confirmar").dialog("close");
                Ember.$("#mensage").text(msg);
                Ember.$("#dialog-alert").dialog({
                    modal: true,
                    buttons: {
                        Ok: function Ok() {
                            console.log("cerrar sesión por usuario");
                            Ember.$(this).dialog("close");

                            // location.reload();  
                            document.location.href = _environment.default.APP.HOST + "/" + _environment.default.baseURL;
                        }
                    }
                });
            } else if (ctrlmsg === false) {
                Ember.$("#mensage").text("La sesión de edición se ha cerrado debido a que estuvo inactiva por más de 35 minutos. Ninguno de los cambios fue guardado.");
                Ember.$("#dialog-confirmar").dialog("close");
                console.log("control mensaje " + controlmesage);
                if (controlmesage === false) {
                    Ember.$("#dialog-alert").dialog({
                        modal: true,
                        buttons: {
                            Ok: function Ok() {
                                console.log("cerrar Sesión no se recibio respuesta");
                                Ember.$(this).dialog("close");
                                //  location.reload();  
                                document.location.href = _environment.default.APP.HOST + "/" + _environment.default.baseURL;
                            }
                        }
                    });
                }
            }
        }
        function logout() {

            var localItem = JSON.parse(localStorage[_environment.default.APP.LS]);
            var token = localItem.json.access_token;
            var query = 'token=' + token;

            Ember.$.ajax({
                url: _environment.default.APP.REST_WSPREFIX + "/" + _environment.default.APP.WSSUFIX + '/sessions/sign_out?' + query,
                type: 'POST'
            }).then(function () {

                var idLocalStorange = localStorage.getItem('idform_activado');
                if (idLocalStorange !== null && idLocalStorange !== "null") {
                    idLocalStorange = parseInt(idLocalStorange.trim());
                    var id = idLocalStorange;
                    var lock = false;
                    /*Si hay un id  en el localstorange  y  cierra session  el formulario en el local storange*/
                    var bodyForm = { "id": idLocalStorange, "lock": lock };
                    Ember.$.ajax({
                        url: _environment.default.APP.REST_WSPREFIX + "/" + _environment.default.APP.WSSUFIX + '/locks/' + idLocalStorange,
                        type: 'PUT',
                        dataType: 'json',
                        contentType: 'application/json',
                        data: JSON.stringify(bodyForm)
                    }).done(function () {
                        if (lock === false) {
                            Ember.$(".fa.fa-lock." + id).css("color", "#008000");
                        } else {
                            Ember.$(".fa.fa-lock." + id).css("color", "#FF0000");
                        }
                        localStorage.setItem('idform_activado', null);
                    });
                }

                delete window.localStorage[_environment.default.APP.LS];
                controller.set('id_usuario', null);

                controller.set('isLogged', false);

                controller.set('isSelectedEstado', false);
                controller.set('esSuperAdmin', false);
                localStorage.setItem('isUserActive', false);
            });
        }

        // se llamará a la función que confirmar Cierre después de 10 segundos
        var temp = setTimeout(confirmarCierre, maxTime);
        //console.log('se activa el temporal');

        //console.log(config.baseURL);


        // cuando se detecte actividad en cualquier parte de la app
        Ember.$(document).on('click keyup keypress keydown blur change', function (e) {
            console.log("e---> " + e);
            isLogeado = localStorage.getItem('isUserActive');
            //console.log("confir cierre  is isLogeado--->" + isLogeado);
            // borrar el temporizador de la funcion confirmarCierre
            clearTimeout(temp);
            // y volver a iniciarlo con 10segs
            temp = setTimeout(confirmarCierre, maxTime);
            //  console.log('actividad detectada');
        });
    }
    // export default Ember.Handlebars.makeBoundHelper(verifysession);
    exports.default = Ember.Helper.helper(verifysession);
});