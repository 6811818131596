define('dynforms-client/components/text-validate', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.TextField.extend({
		change: function change() {
			var re = /^[a-zA-Z0-9]+$/;
			var str = "";
			if (this.$().val() !== null && this.$().val().trim() === '' || !re.test(this.$().val())) {
				Ember.$.prompt("Solo se permiten caracteres alfanuméricos.", {
					overlayspeed: 'fast',
					promptspeed: 'fast',
					title: "Usuarios",
					buttons: { "Aceptar": true },
					submit: function submit(e, answer) {
						if (answer === true) {}
					}
				});
				str = this.$().val();
				str = str.replace(/ /g, "");

				str = str.replace(/[^a-zA-Z 0-9]+/g, '');
				this.$().val(str);
			}
		}
	});
});