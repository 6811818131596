define('dynforms-client/views/categories/subcategory', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.View.extend({
    tagName: 'label',
    mouseEnter: function mouseEnter() {
      this.get('controller').send('hoverEnter', this.get('context'));
    },
    mouseLeave: function mouseLeave() {
      this.get('controller').send('hoverLeave', this.get('context'));
    }
  });
});