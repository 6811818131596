define('dynforms-client/components/email-validate', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.TextField.extend({
		change: function change() {
			var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			if (this.$().val() !== null && this.$().val().trim() === '' || !re.test(this.$().val())) {
				Ember.$.prompt("Por favor ingresa un correo electrónico válido.", {
					overlayspeed: 'fast',
					promptspeed: 'fast',
					title: "Usuarios",
					buttons: { "Aceptar": true },
					submit: function submit(e, answer) {
						if (answer === true) {}
					}
				});
				this.$().val('');
			}
		}
	});
});