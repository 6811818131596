define("dynforms-client/serializers/formulario", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTSerializer.extend({
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {

      if (payload.formularios) {
        var data = [];
        if (payload.formularios.length > 1) {
          payload.formularios.map(function (formulario) {
            var obj = {};

            if (formulario.n_dependencia) {
              formulario.n_dependencia.type = "dependency";
            }

            obj.id = formulario.id;
            obj.type = "forumlarios";
            obj.captcha = formulario.captcha;
            obj.dependency = formulario.n_dependencia;
            obj.fecha_fin = formulario.fecha_fin;
            obj.fecha_inicio = formulario.fecha_inicio;
            obj.lock = formulario.lock;
            obj.n_estado = formulario.n_estado;
            obj.n_tipo_pago = formulario.n_tipo_pago;
            obj.n_tramite = formulario.n_tramite;
            obj.n_usuario = formulario.n_usuario;
            obj.nombre = formulario.nombre;
            obj.status = formulario.status;
            obj.tipo = formulario.tipo;
            obj.total_respuestas = formulario.total_respuestas;

            // obj.relationships.dependencia = formulario.n_dependencia;
            // formulario.type

            data.pushObject(obj);
          });

          payload.formularios = data;
        }
      } else {
        // console.log('formualrio ==> ', payload.formulario);
        if (payload.formulario) {
          var obj = {};

          obj.id = payload.formulario.id;
          obj.captcha = payload.formulario.captcha;
          obj.dependencia = payload.formulario.dependencia;
          obj.status = payload.formulario.estatus;
          obj.fecha_fin = payload.formulario.fecha_fin;
          obj.fecha_inicio = payload.formulario.fecha_inicio;
          obj.formAttx = payload.formulario.formAttx;
          obj.id_dependencia = payload.formulario.id_dependencia;
          obj.id_tramite = payload.formulario.id_tramite;
          obj.lock = payload.formulario.lock;
          obj.nombre = payload.formulario.nombre;
          obj.tipo = payload.formulario.tipo;
          obj.relationships = {};
          obj.relationships.dependency = payload.formulario.dependencia;
          // obj.relationships.tramite = {};
          // obj.relationships.tramite.id = payload.formualrio.tramite.id;


          payload.formulario = obj;
        }
      }
      console.log('return payload => ', payload);

      // return this._super(...arguments);
      return this.normalizeArrayResponse(store, primaryModelClass, payload, id, requestType);
    }
  });
});