define('dynforms-client/models/cost', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var costModel = _emberData.default.Model.extend({
    description: _emberData.default.attr('string'),
    amount: _emberData.default.attr('string'),
    divisa: _emberData.default.attr('string'),
    cfree: _emberData.default.attr('boolean'),
    order: _emberData.default.attr('number'),
    formality: _emberData.default.belongsTo('tramite'),

    amountFormatted: function () {
      if (this.get('amount') !== null) {
        return '$' + this.get('amount').replace(/\B(?=(\d{3})+\b)/g, ",") + ' ' + (this.get('divisa') !== null ? this.get('divisa') : '');
      }
    }.property('amount')

  });

  exports.default = costModel;
});