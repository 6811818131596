define('dynforms-client/helpers/estado-dependencia', ['exports', 'dynforms-client/config/environment'], function (exports, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.dependenciadependencia = dependenciadependencia;
	function dependenciadependencia(dependencia) {

		var input = false;
		if (dependencia === null) {
			input = true;
		} else {
			input = false;
		}

		return input;
	}

	// export default Ember.Handlebars.makeBoundHelper(dependenciadependencia);
	exports.default = Ember.Helper.helper(dependenciadependencia);
});