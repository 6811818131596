define('dynforms-client/helpers/subcats-name', ['exports', 'dynforms-client/config/environment'], function (exports, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.subcatsName = subcatsName;
	function subcatsName(input) {
		if (input != null) {
			var splitInput = input.split(':')[2];
			var value = '';
			if (splitInput !== undefined) {
				value = splitInput.split('"')[1];
			}
			return value;
		}
	}

	// export default Ember.Handlebars.makeBoundHelper(subcatsName);
	exports.default = Ember.Helper.helper(subcatsName);
});