define('dynforms-client/models/documents-group', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var documentGroupModel = _emberData.default.Model.extend({
    title: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    order: _emberData.default.attr('number'),
    note: _emberData.default.attr('string'),
    formality: _emberData.default.belongsTo('tramite', { async: true }),
    documents: _emberData.default.hasMany('documents')
  });

  exports.default = documentGroupModel;
});