define('dynforms-client/routes/dependencies/index', ['exports', 'dynforms-client/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    esSuperAdmin: false,
    beforeModel: function beforeModel() {},
    model: function model() {
      return this.store.findAll('dependency');
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
    }
  });
});