define("dynforms-client/helpers/status-formulario", ["exports", "dynforms-client/config/environment"], function (exports, _environment) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.statusFormulario = statusFormulario;
	function statusFormulario(input) {
		var status = input[0];
		var clazz = "";

		if (status === 1) {
			input = 'Incompleto';
			clazz = "secondary";
		} else if (status === 2) {
			input = 'Borrador';
			clazz = "warning";
		} else if (status === 3) {
			input = 'Publicado';
			clazz = "success";
		}

		return Ember.String.htmlSafe("\n\t\t<div class=\"badge badge-pill badge-" + clazz + " text-center w-50 pt-2 pb-2\">" + input + "</div>\n\t");
	}

	// export default Ember.Handlebars.makeBoundHelper(statusFormulario);
	exports.default = Ember.Helper.helper(statusFormulario);
});