define('dynforms-client/models/user', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    username: _emberData.default.attr('string'),
    password: _emberData.default.attr('string'),
    role: _emberData.default.attr('string'),
    formularios: _emberData.default.hasMany('formulario', { async: true, inverse: 'usuario' }),
    estado_id: _emberData.default.attr('number'), // DS.belongsTo('estado',{ async: true}),
    dependencia_id: _emberData.default.attr('number') // DS.belongsTo('dependency',{ async: true})
  });
});