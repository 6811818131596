define("dynforms-client/helpers/status-tramite", ["exports", "dynforms-client/config/environment"], function (exports, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    compute: function compute(params) {
      var status = params[0];
      var input = "";
      var clazz = "";

      if (status === 0) {
        input = 'Ingresado';
        clazz = "secondary";
      } else if (status === 1) {
        input = 'Espera';
        clazz = "warning";
      } else if (status === 2) {
        input = 'Revisión rechazo';
        clazz = "success";
      } else if (status === 3) {
        input = "Rechazado";
        clazz = "danger";
      } else if (status === 4) {
        input = "Aprobado";
        clazz = "success";
      } else if (status === 5) {
        input = "Completado";
        clazz = "success";
      }

      return Ember.String.htmlSafe("\n      <div class=\"badge badge-lg badge-" + clazz + " w-100 text-center pl-3 pr-3 pt-2 pb-2\">" + input + "</div>\n    ");
    }
  });
});