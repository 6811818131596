define('dynforms-client/models/form_layer', ['exports', 'ember-data'], function (exports, _emberData) {
   'use strict';

   Object.defineProperty(exports, "__esModule", {
      value: true
   });


   var formAttModel = _emberData.default.Model.extend({
      idRelFa: _emberData.default.attr('number'),
      formulario: _emberData.default.belongsTo('formulario', { async: false }),
      atributo: _emberData.default.belongsTo('atributo', { async: false }),
      posicion: _emberData.default.attr('number')
   });

   exports.default = formAttModel;
});