define('dynforms-client/views/userpanel', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.View.extend({
		actions: {
			togglePanel: function togglePanel() {
				$('.dropdown-menu').toggle();
			}
		}
	});
});