define('dynforms-client/helpers/formatted-date', ['exports', 'dynforms-client/config/environment'], function (exports, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.formattedDate = formattedDate;
	function formattedDate(fechai, fechaf) {
		var result = "";
		if (fechai !== null && fechaf !== null) {
			result = ''; // `${fechai} al ${fechaf}`;	
		} else {
			result = "Fecha no definida";
		}

		return result;
	}

	// export default Ember.Handlebars.makeBoundHelper(formattedDate);
	exports.default = Ember.Helper.helper(formattedDate);
});