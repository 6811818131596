define('dynforms-client/components/wysiwyg-container', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		attributeBindings: ['onElement', 'asElement', 'htmlValue'],
		didInsertElement: function didInsertElement() {
			var onElement = $('.' + this.get('onElement'));
			var asElement = $(this.get('asElement'));
			if (this.get('htmlValue') !== null) {
				asElement.html(this.get('htmlValue'));
				asElement.appendTo(onElement);
			}
		}
	});
});