define('dynforms-client/components/render-form', ['exports', 'dynforms-client/config/environment'], function (exports, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		attributeBindings: ["fbTemplate", "renderedContainer"],
		didInsertElement: function didInsertElement() {
			console.log('render');
			var fbTemplate = document.getElementById('template');
			var renderedContainer = document.getElementById('render');
			Ember.$(fbTemplate).bind("change", function () {
				console.log('change??');
				Ember.$(fbTemplate).formRender({
					container: renderedContainer
				});
			});
		}
	});
});