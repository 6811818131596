define('dynforms-client/controllers/login', ['exports', 'dynforms-client/config/environment'], function (exports, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		//authenticator: 'simple-auth-authenticator:oauth2-password-grant'
		message: '',
		needs: ['application', 'home'],
		username: '',
		contrasena: '',
		actions: {
			authenticate: function authenticate() {
				console.log('Autenticando');
				var identification = btoa(this.get('identification'));
				var password = btoa(this.get('password'));
				var controller = this;
				var query = 'grant_type=password&username=' + identification + '&password=' + password + '&origen=true';
				delete window.localStorage[_environment.default.APP.LS];
				if (identification !== undefined && password !== undefined) {

					controller.set('username', identification);
					controller.set('contrasena', password);

					Ember.$.ajax({
						url: _environment.default.APP.REST_WSPREFIX + "/" + _environment.default.APP.WSSUFIX + '/sessions/sign_in?' + query,
						type: 'POST'
					}).then(function (apikey) {
						if (!apikey.hasOwnProperty("error")) {
							window.localStorage.setItem(_environment.default.APP.LS, JSON.stringify(apikey));
							controller.set('password', '');
							controller.get('identification', '');
							controller.transitionToRoute('/');
							// TO GET USER
							//var localItem = JSON.parse(localStorage[config.APP.LS]);
							var userid = apikey.json.user_id;
							var isSelectedEstado = null;
							var role = apikey.json.role;
							localStorage.setItem('isRoleUserActive', role);
							//se activa el icono de alta usuarios para administadores y super administradores
							if (role === 'SA' || role === 'AD' || role === 'EAD') {
								isSelectedEstado = true;
							} else {
								isSelectedEstado = false;
							}
							controller.store.find('user', userid).then(function (user) {
								controller.set('user', user);
								controller.get('controllers.application').set('user', user);
							});
							controller.get('controllers.application').set('isLogged', true);
							var createUserGranted = role !== 'EED' ? true : false;
							controller.get('controllers.home').set('createUserGranted', createUserGranted);
							controller.get('controllers.application').set('createUserGranted', createUserGranted);

							//console.log ("es selected estados-->" + isSelectedEstado);
							controller.get('controllers.application').set('isSelectedEstado', isSelectedEstado);
							controller.get('controllers.home').set('isSelectedEstado', isSelectedEstado);
							localStorage.setItem('isUserActive', true);
						} else {
							$('.alert').removeClass('hidden');
							controller.set('message', 'Usuario y contraseña no coinciden.');
							setTimeout(function () {
								$('.alert').addClass('hidden');
							}, 3000);
							localStorage.setItem('isUserActive', false);
						}
					});
				} else {
					Ember.$('.alert').removeClass('hidden');
					this.set('message', 'Debes de llenar ambos campos.');
					setTimeout(function () {
						$('.alert').addClass('hidden');
					}, 3000);
				}
			},
			goToSolicitud: function goToSolicitud() {
				this.transitionToRoute('solicitud');
			}
		}
	});
});