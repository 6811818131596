define('dynforms-client/controllers/home', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    isSelectedEstado: false,
    isCreateUser: true,
    actions: {
      goToFormulario: function goToFormulario() {
        this.transitionToRoute('/formularios/edit');
      },
      createNewFormulario: function createNewFormulario() {
        this.transitionToRoute('/formularios/new');
      },
      goToUsers: function goToUsers() {
        this.set('isCreateUser', true);
        this.transitionToRoute('/users?newUserEnabled=true');
      },
      goToPreview: function goToPreview(params) {
        this.transitionToRoute('/formularios/show/' + params.id);
      },
      goToReports: function goToReports() {
        // alert('home');
        this.transitionToRoute('/formularios/reports');
      }
    }
  });
});