define('dynforms-client/views/formularios/new', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var formularioNew = Ember.View.extend({
		didInsertElement: function didInsertElement() {},
		actions: {}
	});

	exports.default = formularioNew;
});