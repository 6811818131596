define('dynforms-client/helpers/url-reportes', ['exports', 'dynforms-client/config/environment'], function (exports, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.urlreportes = urlreportes;
	function urlreportes(url) {
		url = '<a href="/link/to/page2">Continue</a>';
		return url;
	}

	// export default Ember.Handlebars.makeBoundHelper(urlreportes);
	exports.default = Ember.Helper.helper(urlreportes);
});