define('dynforms-client/helpers/unblock-form', ['exports', 'dynforms-client/config/environment'], function (exports, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.unblockForm = unblockForm;
	function unblockForm(params /*, hash*/) {
		var idLocalStorange = localStorage.getItem('idform_activado');
		//var idAnclaStorange = localStorage.getItem('idAnclaEditado'); 
		console.log('idLocalStorange----> ' + idLocalStorange);
		if (idLocalStorange !== null && idLocalStorange !== "null") {
			var bodyForm = { "id": idLocalStorange, "lock": false };
			Ember.$.ajax({
				url: _environment.default.APP.REST_WSPREFIX + "/" + _environment.default.APP.WSSUFIX + '/locks/' + parseInt(idLocalStorange),
				type: 'PUT',
				dataType: 'json',
				contentType: 'application/json',
				data: JSON.stringify(bodyForm)
			}).done(function () {

				Ember.$(".fa.fa-lock." + idLocalStorange).css("color", "#52BE80");

				localStorage.setItem('idform_activado', null);
			});
		}
	}
	// export default Ember.Handlebars.Utils.makeBoundHelper(unblockForm);
	exports.default = Ember.Helper.helper(unblockForm);
});