define("dynforms-client/helpers/status-report", ["exports", "dynforms-client/config/environment"], function (exports, _environment) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.statusReport = statusReport;
	function statusReport(input) {
		var status = input[0];
		var result = "";
		var clazz = "";

		console.log("status => ", status);
		if (status || status === "true") {
			result = "Activa";
			clazz = "info";
		} else {
			result = "Concluida";
			clazz = "secondary";
		}

		return Ember.String.htmlSafe("\n\t\t<div class=\"badge badge-lg badge-" + clazz + " text-center w-75 float-left pl-3 pr-3 pt-2 pb-2\">" + result + "</div>\n\t");
	}

	exports.default = Ember.Helper.helper(statusReport);
});