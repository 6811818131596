define('dynforms-client/models/pago', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var tipoPagoModel = _emberData.default.Model.extend({
    tipo_pago: _emberData.default.attr('string'),
    formularios: _emberData.default.hasMany('formulario', { async: true, inverse: 'pago' })
  });

  exports.default = tipoPagoModel;
});