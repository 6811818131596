define('dynforms-client/adapters/application', ['exports', 'ember-data', 'dynforms-client/config/environment', 'ember-inflector'], function (exports, _emberData, _environment, _emberInflector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var RSVP = Ember.RSVP,
      run = Ember.run;


  var AppAdapter = _emberData.default.RESTAdapter.extend({
    host: _environment.default.APP.REST_WSPREFIX,
    namespace: _environment.default.APP.WSSUFIX,
    ajaxError: function ajaxError(jqXHR) {
      var error = this._super(jqXHR);
      if (jqXHR && jqXHR.status === 422) {
        var jsonErrors = Ember.$.parseJSON(jqXHR.responseText);
        return new _emberData.default.InvalidError(jsonErrors);
      } else {
        return error;
      }
    },
    headers: Ember.computed(function () {
      var wls = JSON.parse(localStorage[_environment.default.APP.LS]);
      var token = wls.json.access_token;
      var user = wls.json.user_id;

      return {
        "Authorization": token,
        "X-Authorization": user
      };
    })
  });

  exports.default = AppAdapter;
});