define('dynforms-client/components/number-input', ['exports'], function (exports) {
       'use strict';

       Object.defineProperty(exports, "__esModule", {
              value: true
       });
       exports.default = Ember.TextField.extend({
              keyDown: function keyDown(e) {
                     if (e.keyCode === 46 || e.keyCode === 8 || e.keyCode === 9 || e.keyCode === 27 || e.keyCode === 13 || e.keyCode === 13 || e.ctrlKey === true || e.keyCode === 35 || e.keyCode === 190 || e.keyCode <= 39) {} else {
                            if (e.shiftKey || (e.keyCode < 48 || e.keyCode > 57) && (e.keyCode < 96 || e.keyCode > 105)) {
                                   e.preventDefault();
                            }
                     }
              }
       });
});