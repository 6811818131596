define('dynforms-client/routes/formularios/new', ['exports', 'dynforms-client/config/environment'], function (exports, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		session: Ember.inject.service(),
		isedit: false,
		admin: false,
		btnPublicar: false,
		viewTipoForm: false,
		tramiteactive: false,
		activaBottons: true,
		model: function model() {
			return this.store.createRecord('formulario');
		},
		setupController: function setupController(controller, model) {
			this._super(controller, model);

			var localItem = JSON.parse(localStorage[_environment.default.APP.LS]);
			var processDate = this.browserDateProcess();
			var role = localItem.json.role;
			var id_user = localItem.json.user_id;
			var id_estado = localItem.json.estado;
			var dependencia = localItem.json.dependencia;

			localStorage.setItem('statusForm', 0);

			this.session.validateSession(localItem.json.user_id, localItem.json.access_token).then(function (response) {
				controller.set('credentials', response);
			});

			// TODO abstract role logic, it doesn't belong here & other belongs to user
			// Validacion de si aparece el boton de publicar
			//
			if (role === "SA") {
				controller.set('btnPublicar', true);
				controller.set('viewTipoForm', true);
				controller.set('isAdmin', this.get('admin'));
				controller.set('tramiteactive', true);
				// NULL lets every option
				controller.set('selectedEstado', null);
				controller.set("selectedDependencia", null);
				controller.set('selectedTramite', null);
				controller.set('selectedTipoPago', null);
			} else if (role === "AD" || role === "EAD" || role === "ED" || role === "EED") {
				controller.set('btnPublicar', false);
				// Valida que tipo de usuario es
				if (role === 'ED' || role === 'AD') {
					controller.set('isAdmin', true);
					controller.set('viewTipoForm', false);
					controller.set('tramiteactive', true);
					// role === 'ED'  ??
					controller.set('estadoUser', this.store.find("estado", localItem.json.estado));
					controller.set("selectedEstado", controller.get("estadoUser"));
				} else if (role === 'EED' || role === 'EAD') {
					controller.set('isAdmin', false);
					controller.set('viewTipoForm', false);
					controller.set('tramiteactive', true);
					controller.set('dependenciaUser', this.store.find("dependency", localItem.json.dependencia));
					controller.set("selectedDependencia", controller.get("dependenciaUser"));
				}
			}

			// Build controller configs
			// TODO review and remove duplicate var init on mixin
			//
			controller.set('isedit', false);
			controller.set('activaBottons', true);
			controller.set('isSelectedEstados', localItem.json.estado !== null ? false : true);
			controller.set('tipoForms', Ember.ArrayProxy.create({ content: [{ id: 1, text: 'Encuesta', description: 'Encuesta' }, { id: 2, text: "Trámite", description: "Trámite" }] }));
			controller.set('selectedTipoForm', controller.get('tipoForms').firstObject);
			controller.set('plantillas', [{ id: 1, text: 'DP', description: 'Datos Sociodemográficos' }, { id: 2, text: 'DC', description: 'Datos de Contacto' }]);
			controller.set('dependencias', this.store.findAll('dependency'));
			controller.set('estados', this.store.findAll('estado'));
			controller.set('pagos', this.store.findAll('pago'));

			// Disable at this stage, posible merge with cms trámites
			controller.set("tramites", this.store.findAll("tramite"));
			controller.set("dias", processDate.dias);
			controller.set("meses", processDate.meses);
			controller.set("anios", processDate.anios);

			// Set by default
			controller.set('captcha', true);
			controller.set('role', role);
			controller.set('id_usuario', id_user);
			controller.set('dependencia_id', dependencia);
			controller.set('id_estado', id_estado);
			controller.set('typeExplorer', localStorage.getItem('typeExplorer'));
			controller.set('isSelectedDependencias', localItem.json.dependencia !== null ? false : true);
			controller.set('plantillaView', true);
			controller.set('nameform', null);
			controller.set('startdate', null);
			controller.set('finaldate', null);
			controller.set('plantillasGet', null);
		},
		browserDateProcess: function browserDateProcess() {
			// ABSTRACT validations for browser should be a service
			// Va por la dependencia del usuario
			// Meses en explorer
			var valor = null;
			var mesesString = "";
			for (var i = 1; i <= 12; i++) {
				if (i === 1) {
					mesesString = mesesString + '{"id":"' + i + '","text":"0' + i + '","description":"0' + i + '"}';
				} else {
					valor = "" + i;
					if (i < 10) {
						valor = "0" + i;
					}
					mesesString = mesesString + ',{"id":"' + i + '","text":"' + valor + '","description":"' + valor + '"}';
				}
			}
			/* Dias Explorer*/
			var meses = JSON.parse("[" + mesesString.toString() + "]");
			var diasString = "";
			for (var j = 1; j <= 31; j++) {
				if (j === 1) {
					diasString = diasString + '{"id":"' + j + '","text":"0' + j + '","description": "0' + j + '"}';
				} else {
					valor = j;
					if (j < 10) {
						valor = "0" + j;
					}
					diasString = diasString + ',{"id":"' + j + '","text":"' + valor + '","description":"' + valor + '"}';
				}
			}
			var dias = JSON.parse("[" + diasString.toString() + "]");
			/*
   Anio Explorer*/
			var anioString = "";
			for (var k = 1; k <= 5; k++) {
				valor = 2016 + k;
				if (k === 1) {
					anioString = anioString + '{"id":"' + valor + '","text":"' + valor + '","description":"' + valor + '"}';
				} else {
					anioString = anioString + ',{"id":"' + valor + '","text":"' + valor + '","description":"' + valor + '"}';
				}
			}
			var anios = JSON.parse("[" + anioString.toString() + "]");

			return { dias: dias, meses: meses, anios: anios };
		}
	});
});