define('dynforms-client/components/form-attributes', ['exports', 'dynforms-client/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    attributeBindings: ['onElementId', 'onFormId'],
    didInsertElement: function didInsertElement() {
      var controller = this;
      var miObjeto = {};
      var valid = "";
      var selectedEstado = null;
      var onForm = $('#' + this.get('onElementId'));
      var fromId = this.get('onFormId');
      var formAttributes2 = null;
      Ember.$.ajax({
        url: _environment.default.APP.REST_WSPREFIX + "/" + _environment.default.APP.WSSUFIX + '/formularios/' + fromId,
        type: 'GET',
        dataType: 'json',
        async: false
      }).done(function (jsonObject) {
        formAttributes2 = jsonObject.formAttx;
        controller.set('selectedValEstado', selectedEstado);
        controller.set('isSaved', true);
        if (formAttributes2 !== undefined) {
          try {

            miObjeto.defaultFields = formAttributes2;
            miObjeto.formData = jsonObject.formulario;
            //  console.log('mi objeto--> '+ JSON.stringify( miObjeto));
            valid = 0;
          } catch (err) {
            valid = 1;
          }
          if (valid === 0) {
            onForm.formBuilder(miObjeto);
            localStorage.setItem('statusForm', 0);
          } else {
            Ember.$.prompt("¿Ocurrio un error al procesar los datos guardados. " + "<b>Contacte con su administrador</b>", {
              overlayspeed: 'fast',
              promptspeed: 'fast',
              title: "Formulario",
              buttons: { "Aceptar": true },
              submit: function submit(e, answer) {
                if (answer === true) {
                  onForm.formBuilder();
                }
              }
            });
          }
          var fbTemplate = document.getElementById('fb-template');
          var renderedContainer = document.getElementById('rendered-form');

          $(fbTemplate).formRender({
            container: renderedContainer
          });
        } else {
          onForm.formBuilder();
        }
      }).fail(function () {
        Ember.$.prompt("¿Ocurrio un error durante la carga de datos desde el servidor por favor intente cargar de nuevo. " + "<b>Contacte con su administrador</b>", {
          overlayspeed: 'fast',
          promptspeed: 'fast',
          title: "Formulario",
          buttons: { "Aceptar": true },
          submit: function submit(e, answer) {
            if (answer === true) {
              $("#selectPlantilla").addClass("invisible");
            }
          }
        });
        return "[]";
      });
    }
  });
});