define('dynforms-client/models/formulario', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		lock: _emberData.default.attr("boolean"),
		status: _emberData.default.attr('number'),
		nombre: _emberData.default.attr('string'),
		pago: _emberData.default.belongsTo('pago', { async: true, inverse: 'formularios' }),
		estado: _emberData.default.belongsTo('estado', { async: true, inverse: 'formularios' }),
		tramite: _emberData.default.belongsTo('tramite'), // ,{ async: true, inverse: 'formularios' }),
		usuario: _emberData.default.belongsTo('user', { async: true, inverse: 'formularios' }),
		dependency: _emberData.default.belongsTo('dependency'),
		id_dependencia: _emberData.default.attr('number'),
		// id_tramite: DS.attr('number'),
		fecha_inicio: _emberData.default.attr('string'),
		fecha_fin: _emberData.default.attr('string'),
		id_tipo_pago: _emberData.default.attr('number'),
		captcha: _emberData.default.attr('boolean'),
		tipo: _emberData.default.attr('boolean'),
		publicar: _emberData.default.attr('boolean'),
		total_respuestas: _emberData.default.attr("number"),
		formAttx: _emberData.default.attr(),
		atributos: _emberData.default.attr()
	});
});