define('dynforms-client/controllers/formularios/plantilla', ['exports'], function (exports) {
   'use strict';

   Object.defineProperty(exports, "__esModule", {
      value: true
   });
   exports.default = Ember.Controller.extend({
      plantillas: [{ id: 1, text: 'E', description: 'Estados' }, { id: 2, text: 'DP', description: 'Datos Personales' }, { id: 3, text: 'DC', description: 'Datos de Contacto' }, { id: 4, text: 'PP', description: 'Plantilla Personal' }, { id: 5, text: 'P1', description: 'Plantilla Numero Uno' }]

   });
});