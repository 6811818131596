define('dynforms-client/helpers/check-form', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    attributeBindings: ['onElementId', 'onFormId'],
    didInsertElement: function didInsertElement() {
      /*9
      console.log('form check');
      var controller = this;
      var control = null;
      var fromId = controller.get('onFormId');
      var res = null;
      Ember.$.ajax({
       url: config.APP.REST_WSPREFIX +"/"+config.APP.WSSUFIX+'/formularios/'+fromId,
       type: 'GET',
       dataType: 'json',
       async:false
      }).done(function(result) {
      res = JSON.stringify(result.formAttx);
      
      control = setTimeout(control, 5000);
      })       
      .fail(function() {
      alert('no pudimos tener el enlace');
      });
      function control(){
      var msg =   "El formulario se cerrara en 2 minutos";
      var  botones =  { "Cerrar formulario": true , "Seguir trabajando": false };
      $.prompt(msg , {
        overlayspeed:'fast',
        promptspeed:'fast',
        title: "Mensaje de formularios",
        buttons:botones,
        submit: function(e,answer){
          if(answer === true){
             setTimeout(formAtive, 1000);
           }else if (answer === false) {
           setTimeout(control, 5000); 
         }
       }
      });
      }
      function formAtive(){
      alert("desbloqueado!");
      }
        function formInactive(){
      alert("Seguir bloqueado!");
      }
      }
      */
    }
  });
});