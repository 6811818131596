define('dynforms-client/components/form-atribute-template', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    attributeBindings: ['onElementId', 'onFormId'],
    didInsertElement: function didInsertElement() {
      var formfull = null;

      var plantillas = localStorage.getItem('plantillasLS');
      if (plantillas !== '[]' && plantillas !== "undefined") {
        plantillas = JSON.parse(localStorage.getItem('plantillasLS'));
      } else {
        plantillas = null;
      }
      var formResult = [];
      console.log("plantilllas-----_>" + plantillas);
      var onForm = $('#' + this.get('onElementId'));

      if (plantillas !== undefined && plantillas !== null) {
        for (var i in plantillas) {
          if (plantillas.hasOwnProperty(i)) {
            var resultado = "." + i + " = " + plantillas[i].id + "\n";
            console.log(resultado);
            formfull = this.get('controller').on('compareValues', $.proxy(this.compareValues(plantillas[i].id), this));
            //formfull = compareValues();
            console.log("Result ----> " + JSON.stringify(this.get("formplantilla")));
            formResult = formResult.concat(this.get("formplantilla"));
          }
        }
        //formulario.push(formDataContanct);
        var form = '{ "defaultFields" :' + JSON.stringify(formResult) + '}';
        console.log('FORM BUILDER => ', JSON.stringify(form));
        onForm.formBuilder(JSON.parse(form));
      } else {
        onForm.formBuilder();
      }
      $("#selectPlantilla").addClass("invisible");
    },
    compareValues: function compareValues(value) {
      var formulario = [];
      var formSociodemograficos = null;
      var formDataEspecial = null;
      var formDataContanct = null;
      if (value === 1) {
        formSociodemograficos = [{
          "class": "header form-group col-md-7 ",
          "label": "Datos Sociodemogr&aacute;ficos",
          "type": "header",
          "name": "datos_sociodemograficos",
          "subtype": "h3",
          "element": "Label",
          "number_stepper": "1",
          "number_group": "0"
        }, {
          "class": "form-control text-area form-group col-md-7 ",
          "label": "Educación",
          "name": "educacion",
          "type": "text",
          "regextype": "Sin Validaci&oacute;n",
          "number-stepper": "1",
          "id": 1,
          "dato_abierto": "true"
        }, {
          "class": "form-control text-area form-group col-md-7 ",
          "label": "Estado Civil",
          "name": "ecivil",
          "type": "text",
          "regextype": "Sin Validaci&oacute;n",
          "number-stepper": "1",
          "id": 2,
          "dato_abierto": "true"
        }, {
          "class": "form-control text-area form-group col-md-7 ",
          "label": "Edad",
          "name": "edad",
          "type": "text",
          "regextype": "Sin Validaci&oacute;n",
          "number-stepper": "1",
          "id": 3,
          "dato_abierto": "true"
        }, {
          "class": "form-control text-area form-group col-md-7 ",
          "label": "Género",
          "name": "genero",
          "type": "text",
          "regextype": "Sin Validaci&oacute;n",
          "number-stepper": "1",
          "id": 4,
          "dato_abierto": "true"
        }, {
          "class": "form-control select form-group col-md-7 ",
          "label": "Estado",
          "name": "estado",
          "type": "selectws",
          "wslist": "http://10.20.58.23:3001/vun/estados",
          "wsjerarquia": "1",
          "wsid": "1",
          "response-display": "selectws",
          "number_stepper": "1",
          "number_group": "0",
          "response-value": "selectws",
          "dato_abierto": "true"
        }, {
          "class": "form-control select form-group col-md-7 ",
          "label": "Municipio",
          "name": "Municipio",
          "type": "selectws",
          // "wslist": "http://10.20.58.23:3001/vun/municipios?filter[where][id_estado]={estado}",
          "wsjerarquia": "2",
          "wsid": "2",
          "response-display": "selectws",
          "number_stepper": "1",
          "number_group": "0",
          "dato_abierto": "true",
          "response-value": "selectws"
        }];

        formulario = formSociodemograficos;
      } else if (value === 2) {
        formDataContanct = [{
          "class": "hidden-input form-group col-md-7 ",
          "type": "hr",
          "number_stepper": "1",
          "number_group": "0",
          "dato_abierto": "true"
        }, {
          "class": "header form-group col-md-7 ",
          "label": "Datos de Cont&aacute;cto",
          "type": "header",
          "name": "datos_abiertos",
          "subtype": "h3",
          "element": "Label",
          "number_stepper": "1",
          "number_group": "0",
          "dato_abierto": "true"
        }, {
          "class": "form-control text-area form-group col-md-7 ",
          "label": "Nombre",
          "name": "nombre",
          "type": "text",
          "regextype": "Sin Validaci&oacute;n",
          "number-stepper": "1",
          "number_group": "0",
          "id": 1,
          "dato_abierto": "true"
        }, {
          "class": "form-control text-area form-group col-md-7 ",
          "label": "Apellido Paterno",
          "name": "apaterno",
          "type": "text",
          "regextype": "Sin Validaci&oacute;n",
          "number-stepper": "1",
          "number_group": "0",
          "id": 2,
          "dato_abierto": "true"
        }, {
          "class": "form-control text-area form-group col-md-7 ",
          "label": "Apellido Materno",
          "name": "amaterno",
          "type": "text",
          "regextype": "Sin Validaci&oacute;n",
          "number-stepper": "1",
          "number_group": "0",
          "id": 3,
          "dato_abierto": "true"
        }, {
          "class": "form-control text-area form-group col-md-7 ",
          "label": "Teléfono",
          "name": "telefono",
          "type": "text",
          "regex": "XlswLTldKyQ=",
          "regextype": "Solo N&uacute;meros",
          "number-stepper": "1",
          "number_group": "0",
          "id": 4,
          "dato_abierto": "true"
        }, {
          "class": "form-control text-area form-group col-md-7 ",
          "label": "Correo Electrónico",
          "name": "correo",
          "type": "text",
          "regex": "XihbYS16QS1aMC05X1wuXC1dKStcQCgoW2EtekEtWjAtOVwtXSkrXC4pKyhbYS16QS1aMC05XXsyLDR9KSsk",
          "regextype": "email",
          "number-stepper": "1",
          "number_group": "0",
          "id": 5,
          "dato_abierto": "true"
        }, {
          "class": "form-control text-area form-group col-md-7 ",
          "label": "Comentarios",
          "name": "comentario",
          "type": "textarea",
          "regextype": "Sin Validaci&oacute;n",
          "number-stepper": "1",
          "number_group": "0",
          "id": 6,
          "dato_abierto": "true"
        }];
        formulario = formDataContanct;
      } else if (value === 3) {
        formDataEspecial = [{
          "number_stepper": "1",
          "class": "header form-group col-md-7 ",
          "label": "General",
          "name": "infgral",
          "type": "header",
          "subtype": "h6",
          "number_group": "0",
          "dato_abierto": "true",
          "element": "Stepper",
          "posicion": 1
        }, {
          "element": "Label",
          "number_stepper": "1",
          "number_group": "0",
          "dato_abierto": "true",
          "subtype": "h2",
          "type": "header",
          "name": "header-1492802130500",
          "label": "I. INFORMACI&Oacute;N GENERAL",
          "class": "header form-group col-md-7 ",
          "posicion": 2
        }, {
          "number_group": "0",
          "values": [{
            "value": "empleado",
            "label": "Empleado"
          }, {
            "value": "gerente",
            "label": "Gerente"
          }, {
            "value": "dueno",
            "label": "Dueño"
          }],
          "class": "radio-group form-group col-md-7 ",
          "label": "1. Posici&oacute;n en la empresa de quien responde la encuesta:",
          "name": "posicion",
          "required": "true",
          "type": "radio-group",
          "number_stepper": "1",
          "dato_abierto": "true",
          "posicion": 3
        }, {
          "type": "selectws",
          "response-value": "selectws",
          "dato_abierto": "true",
          "number_group": "0",
          "number_stepper": "1",
          "response-display": "selectws",
          "wsid": "1",
          "wsjerarquia": "1",
          "wslist": "https://www.gob.mx/vun/estados",
          "name": "entidad",
          "label": "2. Entidad Federativa en donde se ubica la empresa:",
          "class": "form-control select form-group col-md-7 ",
          "posicion": 4
        }, {
          "values": [{
            "value": "coparmex",
            "label": "COPARMEX"
          }, {
            "value": "canacintra",
            "label": "CANACINTRA"
          }, {
            "value": "canirac",
            "label": "CANIRAC"
          }, {
            "value": "canaco",
            "label": "CANACO"
          }, {
            "value": "canaco-servytur",
            "label": "CANACO SERVyTUR"
          }, {
            "value": "ammje",
            "label": "AMMJE"
          }, {
            "value": "amhm",
            "label": "AMHM"
          }, {
            "value": "amdetur",
            "label": "AMDETUR"
          }, {
            "value": "amtave",
            "label": "AMTAVE"
          }, {
            "value": "amait",
            "label": "AMAIT"
          }, {
            "value": "amav",
            "label": "AMAV"
          }, {
            "value": "amr",
            "label": "AMR"
          }, {
            "value": "anch",
            "label": "ANCH"
          }, {
            "value": "cna",
            "label": "CNA"
          }, {
            "value": "amocali",
            "label": "AMOCALI"
          }, {
            "value": "ninguna",
            "label": "Ninguna"
          }, {
            "value": "Value",
            "label": "Otro"
          }],
          "class": "checkbox-group form-group col-md-7 ",
          "label": "3. C&aacute;mara o Asociaci&oacute;n a la que pertenece la empresa",
          "name": "camara-asociacion",
          "required": "true",
          "type": "checkbox-group",
          "nseleccionados": "11",
          "importancia": "No",
          "number_stepper": "1",
          "number_group": "0",
          "dato_abierto": "true",
          "posicion": 5
        }, {
          "type": "checkbox-group",
          "importancia": "No",
          "number_stepper": "1",
          "number_group": "0",
          "dato_abierto": "true",
          "nseleccionados": "3",
          "required": "true",
          "name": "servicioscamara",
          "label": "4. De los siguientes servicios de la mencionada C&aacute;mara o Asociaci&oacute;n &iquest;cu&aacute;les son los tres prioritarios para la empresa? ",
          "class": "checkbox-group form-group col-md-7 ",
          "values": [{
            "value": "mercados",
            "label": "Información sobre mercados"
          }, {
            "value": "metodologias",
            "label": "Metodologías para diagnóstico de productividad"
          }, {
            "value": "tecproductos",
            "label": "Información sobre tecnologías de productos y procesos"
          }, {
            "value": "sistemascalidad",
            "label": "Información sobre sistemas / certificaciones de calidad"
          }, {
            "value": "organiztrabajo",
            "label": "Información sobre organización del trabajo"
          }, {
            "value": "gestionrrhh",
            "label": "Información sobre gestión de recursos humanos"
          }, {
            "value": "capacitacion",
            "label": "Capacitación para cuadros gerenciales"
          }, {
            "value": "competencias",
            "label": "Información sobre certificación de competencias de los trabajadores"
          }, {
            "value": "redregional",
            "label": "Programas para desarrollo de una red regional de profesionales consultores"
          }, {
            "value": "apoyoprocneg",
            "label": "Apoyo de las cámaras o asociaciones en procesos de negociación colectiva"
          }, {
            "value": "vinculacionsisteduc",
            "label": "Información sobre la vinculación con el sistema educativo y de investigación"
          }, {
            "value": "rse",
            "label": "Información sobre responsabilidad social empresarial"
          }, {
            "value": "Value",
            "label": "Otro"
          }],
          "posicion": 6
        }, {
          "number_group": "0",
          "number_stepper": "1",
          "textoapoyo": "Sector de acuerdo a la clasificaci&oacute;n del SCIAN 2013",
          "type": "radio-group",
          "values": [{
            "value": "11-agricultura-cria-explotacion",
            "label": "Agricultura, cría y explotación de animales, aprovechamiento forestal, pesca y caza"
          }, {
            "value": "21-mineria",
            "label": "Minería"
          }, {
            "value": "22-generación-transmisiónydistribenergíaeléctrica",
            "label": "Generación, transmisión y distribución de energía eléctrica, suministro de agua y de gas por ductos al consumidor final"
          }, {
            "value": "23-contruccion",
            "label": "Construcción"
          }, {
            "value": "31-33-indmanufactureras",
            "label": "Industrias manufactureras*"
          }, {
            "value": "43-comercioxmayor",
            "label": "Comercio al por mayor"
          }, {
            "value": "46-comercioxmenor",
            "label": "Comercio al por menor"
          }, {
            "value": "48-49-transportes-correosyalmacen",
            "label": "Transportes, correos y almacenamiento"
          }, {
            "value": "51-infmediosmasivos",
            "label": "Información en medios masivos"
          }, {
            "value": "52-servfinancieros",
            "label": "Servicios financieros y de seguros"
          }, {
            "value": "53-servinmobiliarios",
            "label": "Servicios inmobiliarios y de alquiler de bienes muebles e intangibles"
          }, {
            "value": "54-servprofesionales-cientificos",
            "label": "Servicios profesionales, científicos y técnicos"
          }, {
            "value": "55-corporativos",
            "label": "Corporativos"
          }, {
            "value": "56-servapnegoios",
            "label": "Servicios de apoyo a los negocios y manejo de residuos y desechos, y servicios de remediación"
          }, {
            "value": "61-serveducativos",
            "label": "Servicios educativos"
          }, {
            "value": "62-servsaludyasistencia",
            "label": "Servicios de salud y de asistencia social"
          }, {
            "value": "71-servesparcimiento",
            "label": "Servicios de esparcimiento culturales y deportivos, y otros servicios recreativos"
          }, {
            "value": "72-servalojamientotemporal",
            "label": "Servicios de alojamiento temporal y de preparación de alimentos y bebidas"
          }, {
            "value": "81-otrosservs",
            "label": "Otros servicios excepto actividades gubernamentales"
          }, {
            "value": "93-actslegislativas",
            "label": "Actividades legislativas, gubernamentales, de impartición de justicia y de organismos internacionales y extraterritoriales"
          }],
          "required": "true",
          "name": "sector",
          "label": "5. Indica el sector al que pertenece la empresa.",
          "description": "*Industria manufacturera se clasifica en: Productos alimenticios, bebidas y tabaco; Maquinaria y equipo; Derivados del petrundefinedleo y del carbundefinedn, industrias quundefinedmicas del plundefinedstico y del hule; Industrias metundefinedlicas;  Productos a base de minerales no metundefinedlicos; Industrias textiles, prendas de vestir e industrias del cuero;  Papel, impresiundefinedn e industrias relacionadas; Otras industrias manufactureras; Industria de la madera; y, Fabricaciundefinedn de muebles y productos relacionados.",
          "class": "radio-group form-group col-md-7 ",
          "dato_abierto": "true",
          "posicion": 7
        }, {
          "type": "header",
          "dato_abierto": "true",
          "number_group": "0",
          "number_stepper": "2",
          "element": "Stepper",
          "subtype": "h6",
          "name": "header-1492804292327",
          "label": "Empleo",
          "class": "header form-group col-md-7 ",
          "posicion": 8
        }, {
          "number_stepper": "2",
          "label": "II. EMPLEO",
          "name": "empleo",
          "type": "header",
          "subtype": "h2",
          "element": "Label",
          "textoapoyo": "Ingresos y trabajo productivo.",
          "number_group": "0",
          "dato_abierto": "true",
          "class": " form-group col-md-7 ",
          "posicion": 9
        }, {
          "number_stepper": "2",
          "textoapoyo": "Favor de utilizar la siguiente referencia: Sector Agropecuario:  Micro: 0 a 10 trabajadores; Peque&ntilde;a: 11 a 50 trabajadores; Mediana 51 a 250 trabajadores; Grande: m&aacute;s de 250 trabajadores. Sector Industria:  Micro: 0 a 10 trabajadores; Peque&ntilde;a: 11 a 50 trabajadores; Mediana 51 a 250 trabajadores; Grande: m&aacute;s de 250 trabajadores. Sector Comercio:  Micro: 0 a 10 trabajadores; Peque&ntilde;a: 11 a 30 trabajadores; Mediana 31 a 100 trabajadores; Grande: m&aacute;s de 100 trabajadores. Sector Servicios:  Micro: 0 a 10 trabajadores; Peque&ntilde;a: 11 a 50 trabajadores; Mediana 51 a 100 trabajadores; Grande: m&aacute;s de 100 trabajadores.",
          "type": "radio-group",
          "label": "6. Se&ntilde;ala el tama&ntilde;o de la empresa de acuerdo con el sector y n&uacute;mero de trabajadores. ",
          "description": "Sector Agropecuario:  Micro: 0 a 10 trabajadores; Pequeundefineda: 11 a 50 trabajadores; Mediana 51 a 250 trabajadores; Grande: mundefineds de 250 trabajadores. Sector Industria:  Micro: 0 a 10 trabajadores; Pequeundefineda: 11 a 50 trabajadores; Mediana 51 a 250 trabajadores; Grande: mundefineds de 250 trabajadores. Sector Comercio:  Micro: 0 a 10 trabajadores; Pequeundefineda: 11 a 30 trabajadores; Mediana 31 a 100 trabajadores; Grande: mundefineds de 100 trabajadores. Sector Servicios:  Micro: 0 a 10 trabajadores; Pequeundefineda: 11 a 50 trabajadores; Mediana 51 a 100 trabajadores; Grande: mundefineds de 100 trabajadores.",
          "class": "radio-group form-group col-md-7 ",
          "values": [{
            "value": "micro",
            "label": "Micro"
          }, {
            "value": "pequena",
            "label": "Pequeña"
          }, {
            "value": "mediana",
            "label": "Mediana"
          }, {
            "value": "grande",
            "label": "Grande"
          }],
          "name": "tamano",
          "required": "true",
          "dato_abierto": "true",
          "number_group": "0",
          "posicion": 10
        }, {
          "number_stepper": "2",
          "class": " form-group col-md-7 ",
          "label": "7. Indica el n&uacute;mero de mujeres y hombres en la empresa.",
          "name": "numero-h-m",
          "type": "header",
          "subtype": "h5",
          "element": "Label",
          "number_group": "0",
          "dato_abierto": "true",
          "posicion": 11
        }, {
          "number_stepper": "2",
          "label": "Mujeres:",
          "class": "form-control text-input form-group col-md-7 ",
          "maxlength": "5",
          "name": "p7mujeres",
          "required": "true",
          "type": "label",
          "subtype": "label",
          "regextype": "Sin Validaci&oacute;n",
          "number_group": "0",
          "dato_abierto": "true",
          "posicion": 12
        }, {
          "number_group": "0",
          "dato_abierto": "true",
          "class": "form-control text-input form-group col-md-7 ",
          "label": "Hombres",
          "maxlength": "5",
          "name": "p7hombres",
          "required": "true",
          "type": "label",
          "subtype": "label",
          "regextype": "Sin Validaci&oacute;n",
          "number_stepper": "2",
          "posicion": 13
        }, {
          "regextype": "Sin Validaci&oacute;n",
          "class": "form-control text-input form-group col-md-7 ",
          "label": "8. Indica la antig&uuml;edad promedio de los trabajadores en la empresa.",
          "maxlength": "20",
          "name": "antiguedadtrab",
          "required": "true",
          "type": "label",
          "subtype": "label",
          "textoapoyo": "Unidad en a&ntilde;os.",
          "number_stepper": "2",
          "number_group": "0",
          "dato_abierto": "true",
          "posicion": 14
        }, {
          "label": "9. Indica la escolaridad promedio de los trabajadores en la empresa.",
          "maxlength": "5",
          "name": "escolaridad",
          "required": "true",
          "type": "label",
          "subtype": "label",
          "regextype": "Sin Validaci&oacute;n",
          "textoapoyo": "Unidad en a&ntilde;os.",
          "number_stepper": "2",
          "number_group": "0",
          "dato_abierto": "true",
          "class": "form-control text-input form-group col-md-7 ",
          "posicion": 15
        }, {
          "dato_abierto": "true",
          "number_stepper": "2",
          "number_group": "0",
          "values": [{
            "value": "sueldo",
            "label": "Sueldo, salario o jornal"
          }, {
            "value": "comision",
            "label": "Comisión"
          }, {
            "value": "destajo",
            "label": "A destajo (pieza), servicio u obra realizada"
          }, {
            "value": "honorarios",
            "label": "Honorarios"
          }, {
            "value": "propinas",
            "label": "Propinas"
          }, {
            "value": "bonos",
            "label": "Bonos de compensación o productividad"
          }, {
            "value": "vales",
            "label": "Vales o productos comercializables"
          }, {
            "value": "Value",
            "label": "Otro"
          }],
          "class": "checkbox-group form-group col-md-7 ",
          "label": "10. Los trabajadores reciben ingresos por: ",
          "name": "ingresos",
          "required": "true",
          "type": "checkbox-group",
          "nseleccionados": "8",
          "importancia": "No",
          "posicion": 16
        }, {
          "values": [{
            "value": "total",
            "label": "Totalmente de acuerdo"
          }, {
            "value": "deacuerdo",
            "label": "De acuerdo"
          }, {
            "value": "nia-nides",
            "label": "Ni de acuerdo, ni en desacuerdo"
          }, {
            "value": "des",
            "label": "En desacuerdo"
          }, {
            "value": "totaldes",
            "label": "Totalmente en desacuerdo"
          }],
          "class": "radio-group form-group col-md-7 ",
          "label": "11. El pago que reciben los trabajadores de la empresa es justo",
          "name": "pagojusto",
          "required": "true",
          "type": "radio-group",
          "number_stepper": "2",
          "number_group": "0",
          "dato_abierto": "true",
          "posicion": 17
        }, {
          "number_stepper": "2",
          "regextype": "Sin Validaci&oacute;n",
          "subtype": "label",
          "type": "label",
          "required": "true",
          "name": "despidos-renuncias",
          "maxlength": "10",
          "label": "12. &iquest;Cu&aacute;ntos despidos y renuncias hay en un a&ntilde;o por cada 10 trabajadores de la empresa?",
          "class": "form-control text-input form-group col-md-7 ",
          "number_group": "0",
          "dato_abierto": "true",
          "posicion": 18
        }, {
          "values": [{
            "value": "15",
            "label": "Menor de 15 años"
          }, {
            "value": "15-19",
            "label": "Entre 15 y 19 años"
          }, {
            "value": "20-29",
            "label": "Entre 20 y 29 años"
          }, {
            "value": "30-39",
            "label": "Entre 30 y 39 años"
          }, {
            "value": "40-49",
            "label": "Entre 40 y 49 años"
          }, {
            "value": "50-59",
            "label": "Entre 50 y 59 años"
          }, {
            "value": "60ymas",
            "label": "60 años y más"
          }],
          "class": "radio-group form-group col-md-7 ",
          "label": "13. &iquest;Cu&aacute;l es el rango de edad promedio del personal de nuevo ingreso, (&uacute;ltimo a&ntilde;o)?",
          "name": "rangoedad",
          "required": "true",
          "type": "radio-group",
          "number_stepper": "2",
          "number_group": "0",
          "dato_abierto": "true",
          "posicion": 19
        }, {
          "label": "14. &iquest;La empresa mide su productividad? ",
          "values": [{
            "value": "si",
            "label": "Sí"
          }, {
            "value": "no",
            "label": "No"
          }],
          "class": "radio-group form-group col-md-7 ",
          "name": "mideproductiv",
          "required": "true",
          "type": "radio-group",
          "number_stepper": "2",
          "number_group": "0",
          "dato_abierto": "true",
          "posicion": 20
        }, {
          "type": "header",
          "subtype": "h6",
          "element": "Label",
          "number_stepper": "2",
          "number_group": "1",
          "class": " form-group col-md-7 ",
          "dato_abierto": "true",
          "name": "productiv-pax",
          "label": "15. &iquest;Qu&eacute; tan productivas son las personas que laboran en la empresa, de acuerdo con los siguientes rangos de edad? ",
          "posicion": 21
        }, {
          "values": [{
            "value": "muy",
            "label": "Muy productivo"
          }, {
            "value": "regular",
            "label": "Regularmente productivo"
          }, {
            "value": "poco",
            "label": "Poco productivo"
          }, {
            "value": "nada",
            "label": "Nada productivo"
          }, {
            "value": "na",
            "label": "No aplica"
          }],
          "class": "form-control select form-group col-md-7 ",
          "label": "Menor de 15 a&ntilde;os",
          "multiple": "true",
          "name": "p15-menor15",
          "required": "true",
          "type": "select",
          "number_stepper": "2",
          "number_group": "1",
          "dato_abierto": "true",
          "posicion": 22
        }, {
          "type": "select",
          "values": [{
            "value": "muy",
            "label": "Muy productivo"
          }, {
            "value": "regular",
            "label": "Regularmente productivo"
          }, {
            "value": "poco",
            "label": "Poco productivo"
          }, {
            "value": "nada",
            "label": "Nada productivo"
          }, {
            "value": "na",
            "label": "No aplica"
          }],
          "class": "form-control select form-group col-md-7 ",
          "label": "Entre 15 y 19 a&ntilde;os",
          "multiple": "true",
          "name": "p15-15-19",
          "required": "true",
          "number_stepper": "2",
          "number_group": "1",
          "dato_abierto": "true",
          "posicion": 23
        }, {
          "label": "Entre 20 y 29 a&ntilde;os",
          "class": "form-control select form-group col-md-7 ",
          "values": [{
            "value": "muy",
            "label": "Muy productivo"
          }, {
            "value": "regular",
            "label": "Regularmente productivo"
          }, {
            "value": "poco",
            "label": "Poco productivo"
          }, {
            "value": "nada",
            "label": "Nada productivo"
          }, {
            "value": "na",
            "label": "No aplica"
          }],
          "name": "p15-20-29",
          "multiple": "true",
          "type": "select",
          "required": "true",
          "dato_abierto": "true",
          "number_group": "1",
          "number_stepper": "2",
          "posicion": 24
        }, {
          "name": "p15-30-39",
          "dato_abierto": "true",
          "number_group": "1",
          "label": "Entre 30 y 39 a&ntilde;os",
          "class": "form-control select form-group col-md-7 ",
          "values": [{
            "value": "muy",
            "label": "Muy productivo"
          }, {
            "value": "regular",
            "label": "Regularmente productivo"
          }, {
            "value": "poco",
            "label": "Poco productivo"
          }, {
            "value": "nada",
            "label": "Nada productivo"
          }, {
            "value": "na",
            "label": "No aplica"
          }],
          "number_stepper": "2",
          "type": "select",
          "required": "true",
          "multiple": "true",
          "posicion": 25
        }, {
          "number_stepper": "2",
          "dato_abierto": "true",
          "number_group": "1",
          "type": "select",
          "required": "true",
          "name": "p15-40-49",
          "multiple": "true",
          "label": "Entre 40 y 49 a&ntilde;os ",
          "class": "form-control select form-group col-md-7 ",
          "values": [{
            "value": "muy",
            "label": "Muy productivo"
          }, {
            "value": "regular",
            "label": "Regularmente productivo"
          }, {
            "value": "poco",
            "label": "Poco productivo"
          }, {
            "value": "nada",
            "label": "Nada productivo"
          }, {
            "value": "na",
            "label": "No aplica"
          }],
          "posicion": 26
        }, {
          "values": [{
            "value": "muy",
            "label": "Muy productivo"
          }, {
            "value": "regular",
            "label": "Regularmente productivo"
          }, {
            "value": "poco",
            "label": "Poco productivo"
          }, {
            "value": "nada",
            "label": "Nada productivo"
          }, {
            "value": "na",
            "label": "No aplica"
          }],
          "class": "form-control select form-group col-md-7 ",
          "label": "Entre 50 y 59 a&ntilde;os",
          "multiple": "true",
          "name": "p-15-50-59",
          "required": "true",
          "type": "select",
          "number_stepper": "2",
          "number_group": "1",
          "dato_abierto": "true",
          "posicion": 27
        }, {
          "dato_abierto": "true",
          "number_stepper": "2",
          "type": "select",
          "number_group": "1",
          "required": "true",
          "values": [{
            "value": "muy",
            "label": "Muy productivo"
          }, {
            "value": "regular",
            "label": "Regularmente productivo"
          }, {
            "value": "poco",
            "label": "Poco productivo"
          }, {
            "value": "nada",
            "label": "Nada productivo"
          }, {
            "value": "na",
            "label": "No aplica"
          }],
          "class": "form-control select form-group col-md-7 ",
          "label": "60 a&ntilde;os y m&aacute;s",
          "multiple": "true",
          "name": "p15-60ymas",
          "posicion": 28
        }, {
          "required": "true",
          "name": "experiencia",
          "type": "radio-group",
          "number_stepper": "2",
          "number_group": "0",
          "dato_abierto": "true",
          "values": [{
            "value": "total",
            "label": "Totalmente de acuerdo"
          }, {
            "value": "deacuerdo",
            "label": "De acuerdo"
          }, {
            "value": "nia-nides",
            "label": "Ni de acuerdo, ni en desacuerdo"
          }, {
            "value": "des",
            "label": "En desacuerdo"
          }, {
            "value": "totaldes",
            "label": "Totalmente en desacuerdo"
          }],
          "class": "radio-group form-group col-md-7 ",
          "label": "16. La experiencia de las personas es un factor importante para que desempe&ntilde;en sus actividades.",
          "posicion": 29
        }, {
          "label": "17. Indica de qu&eacute; depende la productividad en la empresa.",
          "name": "productivempresa-depende",
          "required": "true",
          "type": "checkbox-group",
          "nseleccionados": "3",
          "textoapoyo": "Marca tres opciones.",
          "importancia": "No",
          "number_stepper": "2",
          "number_group": "0",
          "dato_abierto": "true",
          "values": [{
            "value": "clima",
            "label": "Clima laboral"
          }, {
            "value": "maquinaria",
            "label": "Maquinaria, equipo, herramienta y materiales de trabajo"
          }, {
            "value": "competencias",
            "label": "Competencias de los trabajadores"
          }, {
            "value": "procesos",
            "label": "Procesos de trabajo"
          }, {
            "value": "capacitacion",
            "label": "Capacitación"
          }, {
            "value": "innovacion",
            "label": "Innovación"
          }, {
            "value": "condiciones",
            "label": "Condiciones de trabajo"
          }, {
            "value": "ingreso",
            "label": "Ingreso justo"
          }, {
            "value": "remuneracion",
            "label": "Sistema de remuneración"
          }, {
            "value": "incentivos",
            "label": "Aplicación de incentivos no materiales"
          }, {
            "value": "seguridad",
            "label": "La seguridad en el lugar de trabajo"
          }, {
            "value": "accesoss",
            "label": "Acceso a la seguridad social"
          }, {
            "value": "despersonal",
            "label": "Desarrollo personal e integración social"
          }, {
            "value": "libertad",
            "label": "Libertad para expresar sus opiniones"
          }, {
            "value": "igualdad",
            "label": "Igualdad / Equidad"
          }, {
            "value": "balance",
            "label": "Balance vida laboral - familia"
          }, {
            "value": "Value",
            "label": "Otro"
          }],
          "class": "checkbox-group form-group col-md-7 ",
          "posicion": 30
        }, {
          "nseleccionados": "3",
          "values": [{
            "value": "ventasuf",
            "label": "Ventas en unidades físicas"
          }, {
            "value": "ventasum",
            "label": "Ventas en unidades monetarias"
          }, {
            "value": "insumos",
            "label": "Costo de los insumos"
          }, {
            "value": "noclientes",
            "label": "Número de clientes"
          }, {
            "value": "elabproducto",
            "label": "Tiempos en la elaboración del producto"
          }, {
            "value": "tatencion",
            "label": "Tiempos de atención"
          }, {
            "value": "tespera",
            "label": "Tiempo de espera"
          }, {
            "value": "entrega",
            "label": "Entrega a tiempo"
          }, {
            "value": "nodevoluciones",
            "label": "Número de devoluciones"
          }, {
            "value": "noquejas",
            "label": "Número de quejas"
          }, {
            "value": "satisfaccion",
            "label": "Satisfacción del cliente"
          }, {
            "value": "volumen",
            "label": "Volumen de producción"
          }, {
            "value": "tproduccion",
            "label": "Tiempos en producción"
          }, {
            "value": "upxtrab",
            "label": "Unidades producidas por trabajador"
          }, {
            "value": "calidad",
            "label": "Calidad del producto / servicio"
          }, {
            "value": "nopdefectuosos",
            "label": "Número de productos defectuosos"
          }, {
            "value": "mermas",
            "label": "Mermas y desperdicios"
          }, {
            "value": "tperdidos",
            "label": "Tiempo perdidos / muertos"
          }, {
            "value": "tdistribucion",
            "label": "Tiempos de distribución"
          }, {
            "value": "rotacioninv",
            "label": "Rotación de inventarios"
          }, {
            "value": "noaccidentes",
            "label": "Número de accidentes de trabajo"
          }, {
            "value": "primariesgo",
            "label": "Prima de riesgo de trabajo"
          }, {
            "value": "gtosadmon",
            "label": "Gastos de administración y ventas"
          }, {
            "value": "rotacionpersonal",
            "label": "Rotación de personal y ausentismo"
          }, {
            "value": "Value",
            "label": "Otro"
          }],
          "label": "18. Se&ntilde;ala cu&aacute;les son los tres principales indicadores de productividad en la empresa.",
          "number_group": "0",
          "dato_abierto": "true",
          "number_stepper": "2",
          "importancia": "No",
          "type": "checkbox-group",
          "required": "true",
          "name": "indicadoresproductividad",
          "class": "checkbox-group form-group col-md-7 ",
          "posicion": 31
        }, {
          "type": "radio-group",
          "required": "true",
          "name": "beneficios-incentivos",
          "label": "19. &iquest;Los trabajadores reciben alg&uacute;n beneficio/incentivo derivado de un incremento en la productividad?",
          "class": "radio-group form-group col-md-7 ",
          "number_stepper": "2",
          "values": [{
            "value": "si",
            "label": "Sí"
          }, {
            "value": "no",
            "label": "No"
          }],
          "dato_abierto": "true",
          "number_group": "0",
          "posicion": 32
        }, {
          "label": "20. La empresa promueve la cooperaci&oacute;n entre el personal y aprecia los aportes de mejora",
          "values": [{
            "value": "total",
            "label": "Totalmente de acuerdo"
          }, {
            "value": "deacuerdo",
            "label": "De acuerdo"
          }, {
            "value": "nia-nides",
            "label": "Ni de acuerdo, ni en desacuerdo"
          }, {
            "value": "des",
            "label": "En desacuerdo"
          }, {
            "value": "totaldes",
            "label": "Totalmente en desacuerdo"
          }],
          "class": "radio-group form-group col-md-7 ",
          "name": "cooperacion",
          "required": "true",
          "type": "radio-group",
          "number_stepper": "2",
          "number_group": "0",
          "dato_abierto": "true",
          "posicion": 33
        }, {
          "number_group": "0",
          "number_stepper": "2",
          "importancia": "No",
          "nseleccionados": "3",
          "type": "checkbox-group",
          "required": "true",
          "name": "causasbajaprod",
          "label": "21. Indica las tres principales causas que en la empresa generan una baja productividad.",
          "class": "checkbox-group form-group col-md-7 ",
          "values": [{
            "value": "instalaciones",
            "label": "Instalaciones inadecuadas"
          }, {
            "value": "faltarecursos",
            "label": "Falta de recursos de trabajo (maquinaria, equipo, herramientas y materiales de trabajo)"
          }, {
            "value": "climalab",
            "label": "Clima laboral"
          }, {
            "value": "politicas",
            "label": "Políticas que rigen a los integrantes de la empresa"
          }, {
            "value": "liderazgo",
            "label": "Liderazgo ineficiente dentro de la organización"
          }, {
            "value": "factecon",
            "label": "Factores económicos externos"
          }, {
            "value": "probpersonales",
            "label": "Problemas personales fuera de la organización que afectan el desempeño"
          }, {
            "value": "defcomunicacion",
            "label": "Deficiente comunicación"
          }, {
            "value": "faltacap",
            "label": "Falta de capacitación"
          }, {
            "value": "ausentismo",
            "label": "Ausentismo"
          }, {
            "value": "rotacion",
            "label": "Rotación de personal"
          }, {
            "value": "faltahab",
            "label": "Falta de habilidades de los trabajadores"
          }, {
            "value": "sinespecializacion",
            "label": "Trabajadores sin / baja especialización"
          }, {
            "value": "pocaexper",
            "label": "Poca experiencia de los trabajadores"
          }, {
            "value": "co",
            "label": "Cultura organizacional"
          }, {
            "value": "condicionessys",
            "label": "Condiciones de seguridad y salud"
          }, {
            "value": "edad",
            "label": "Edad de los trabajadores"
          }, {
            "value": "Value",
            "label": "Otro"
          }],
          "dato_abierto": "true",
          "posicion": 34
        }, {
          "label": "Derechos",
          "name": "header-1492804393800",
          "type": "header",
          "subtype": "h6",
          "element": "Stepper",
          "number_stepper": "3",
          "number_group": "0",
          "dato_abierto": "true",
          "class": "header form-group col-md-7 ",
          "posicion": 35
        }, {
          "label": "III. PRINCIPIOS Y DERECHOS LABORALES",
          "type": "header",
          "subtype": "h2",
          "element": "Label",
          "textoapoyo": "Horas de trabajo decente, entorno de trabajo seguro, capacitaci&oacute;n y abolici&oacute;n del trabajo infantil.",
          "number_stepper": "3",
          "number_group": "0",
          "dato_abierto": "true",
          "name": "principios",
          "class": " form-group col-md-7 ",
          "posicion": 36
        }, {
          "dato_abierto": "true",
          "values": [{
            "value": "si",
            "label": "Sí"
          }, {
            "value": "no",
            "label": "No"
          }],
          "class": "radio-group form-group col-md-7 ",
          "label": "22. &iquest;Los trabajadores cuentan con un contrato por escrito?",
          "name": "contrato",
          "required": "true",
          "type": "radio-group",
          "number_stepper": "3",
          "number_group": "0",
          "posicion": 37
        }, {
          "number_stepper": "3",
          "type": "radio-group",
          "required": "true",
          "name": "derechoslab",
          "label": "23. &iquest;La empresa da a conocer a los trabajadores sus derechos laborales?",
          "class": "radio-group form-group col-md-7 ",
          "values": [{
            "value": "si",
            "label": "Sí"
          }, {
            "value": "no",
            "label": "No"
          }],
          "dato_abierto": "true",
          "number_group": "0",
          "posicion": 38
        }, {
          "dato_abierto": "true",
          "number_stepper": "3",
          "type": "radio-group",
          "required": "true",
          "name": "obligacioneslab",
          "label": "24. &iquest;La empresa da a conocer a los trabajadores sus obligaciones laborales?",
          "class": "radio-group form-group col-md-7 ",
          "values": [{
            "value": "si",
            "label": "Sí"
          }, {
            "value": "no",
            "label": "No"
          }],
          "number_group": "0",
          "posicion": 39
        }, {
          "class": "radio-group form-group col-md-7 ",
          "label": "25. &iquest;Cu&aacute;ntas horas en promedio labora el personal a la semana? ",
          "name": "horaspromedio",
          "required": "true",
          "type": "radio-group",
          "number_stepper": "3",
          "number_group": "0",
          "dato_abierto": "true",
          "values": [{
            "value": "menos15",
            "label": "Menos de 15 horas"
          }, {
            "value": "15-24",
            "label": "De 15 a 24 horas"
          }, {
            "value": "25-34",
            "label": "De 25 a 34 horas"
          }, {
            "value": "35-39",
            "label": "De 35 a 39 horas"
          }, {
            "value": "40-48",
            "label": "De 40 a 48 horas"
          }, {
            "value": "49-56",
            "label": "De 49 a 56 horas"
          }, {
            "value": "mas56",
            "label": "Más de 56 horas"
          }],
          "posicion": 40
        }, {
          "required": "true",
          "values": [{
            "value": "si",
            "label": "Sí"
          }, {
            "value": "no",
            "label": "No"
          }],
          "dato_abierto": "true",
          "number_group": "0",
          "number_stepper": "3",
          "type": "radio-group",
          "name": "pagohrsextra",
          "label": "26. &iquest;Existe el pago de horas extras en la empresa? ",
          "class": "radio-group form-group col-md-7 ",
          "posicion": 41
        }, {
          "type": "radio-group",
          "dato_abierto": "true",
          "number_group": "0",
          "number_stepper": "3",
          "required": "true",
          "name": "condicionesseguras",
          "label": "27. En la empresa las condiciones de trabajo son seguras.",
          "class": "radio-group form-group col-md-7 ",
          "values": [{
            "value": "total",
            "label": "Totalmente de acuerdo"
          }, {
            "value": "deacuerdo",
            "label": "De acuerdo"
          }, {
            "value": "nia-nides",
            "label": "Ni de acuerdo, ni en desacuerdo"
          }, {
            "value": "des",
            "label": "En desacuerdo"
          }, {
            "value": "totaldes",
            "label": "Totalmente en desacuerdo"
          }],
          "posicion": 42
        }, {
          "values": [{
            "value": "si",
            "label": "Sí"
          }, {
            "value": "no",
            "label": "No"
          }],
          "class": "radio-group form-group col-md-7 ",
          "label": "28. &iquest;Existe en la empresa un encargado o &aacute;rea responsable de la seguridad y salud en el trabajo?",
          "name": "responsablesst",
          "required": "true",
          "type": "radio-group",
          "number_stepper": "3",
          "number_group": "0",
          "dato_abierto": "true",
          "posicion": 43
        }, {
          "class": "radio-group form-group col-md-7 ",
          "dato_abierto": "true",
          "number_group": "0",
          "number_stepper": "3",
          "type": "radio-group",
          "required": "true",
          "name": "comisionsst",
          "label": "29. &iquest;Cuentan con una comisi&oacute;n de seguridad y salud en el trabajo?",
          "values": [{
            "value": "si",
            "label": "Sí"
          }, {
            "value": "no",
            "label": "No"
          }],
          "posicion": 44
        }, {
          "dato_abierto": "true",
          "values": [{
            "value": "si",
            "label": "Sí"
          }, {
            "value": "no",
            "label": "No"
          }],
          "class": "radio-group form-group col-md-7 ",
          "label": "30. &iquest;La empresa tiene un programa de seguridad y salud en el trabajo? ",
          "name": "programasst",
          "required": "true",
          "type": "radio-group",
          "number_stepper": "3",
          "number_group": "0",
          "posicion": 45
        }, {
          "dato_abierto": "true",
          "number_group": "0",
          "number_stepper": "3",
          "type": "radio-group",
          "required": "true",
          "name": "normativsst",
          "values": [{
            "value": "total",
            "label": "Totalmente de acuerdo"
          }, {
            "value": "deacuerdo",
            "label": "De acuerdo"
          }, {
            "value": "nia-nides",
            "label": "Ni de acuerdo, ni en desacuerdo"
          }, {
            "value": "des",
            "label": "En desacuerdo"
          }, {
            "value": "totaldes",
            "label": "Totalmente en desacuerdo"
          }],
          "class": "radio-group form-group col-md-7 ",
          "label": "31. Los trabajadores tienen conocimiento sobre la normatividad en materia de seguridad y salud en el trabajo de acuerdo con sus actividades.",
          "posicion": 46
        }, {
          "dato_abierto": "true",
          "values": [{
            "value": "diaria",
            "label": "Diaria"
          }, {
            "value": "semanal",
            "label": "Semanal"
          }, {
            "value": "mensul",
            "label": "Mensual"
          }, {
            "value": "trimestral",
            "label": "Trimestral"
          }, {
            "value": "semestral",
            "label": "Semestral"
          }, {
            "value": "nunca",
            "label": "Nunca"
          }],
          "class": "radio-group form-group col-md-7 ",
          "label": "32. &iquest;Con qu&eacute; frecuencia se presentan condiciones inseguras o riesgos de trabajo en la empresa?",
          "name": "freccondicionesinseguras",
          "required": "true",
          "type": "radio-group",
          "number_stepper": "3",
          "number_group": "0",
          "posicion": 47
        }, {
          "label": "33. Indica las tres condiciones inseguras que se presentan con mayor frecuencia en la empresa. ",
          "name": "condionesinseguras",
          "required": "true",
          "type": "checkbox-group",
          "nseleccionados": "3",
          "values": [{
            "value": "suciedad",
            "label": "Suciedad y desorden en el área de trabajo"
          }, {
            "value": "cables",
            "label": "Cables energizados en mal estado (expuestos, rotos, pelados)"
          }, {
            "value": "pasillos",
            "label": "Pasillos, escaleras y puertas obstruidas"
          }, {
            "value": "pisos",
            "label": "Pisos en malas condiciones"
          }, {
            "value": "escaleras",
            "label": "Escaleras sin pasamanos"
          }, {
            "value": "ventilacion",
            "label": "Deficiente ventilación"
          }, {
            "value": "incendios",
            "label": "No cuentan con medidas de prevención y protección contra incendios como extintores o no existe una separación de productos químicos"
          }, {
            "value": "eqprotec",
            "label": "Equipo de protección personal defectuoso, inadecuado o faltante"
          }, {
            "value": "faltaidentsustquim",
            "label": "Falta de identificación de sustancias químicas"
          }, {
            "value": "ergonomicas",
            "label": "Deficiencias ergonómicas (sillas, incomodidad al realizar tareas, levantar cargas pesadas, tareas repetitivas, otros)"
          }, {
            "value": "senaletica",
            "label": "Falta de señalética"
          }, {
            "value": "Value",
            "label": "Otro"
          }],
          "importancia": "No",
          "class": "checkbox-group form-group col-md-7 ",
          "number_stepper": "3",
          "number_group": "0",
          "dato_abierto": "true",
          "posicion": 48
        }, {
          "values": [{
            "value": "exposicion",
            "label": "Exposición a un agente físico, como el ruido, vibraciones, exceso o deficiencia de iluminación, radiaciones ionizantes o no ionizantes y temperaturas extremas"
          }, {
            "value": "trabalturas-confinados",
            "label": "Trabajo en alturas y/o espacios confinados"
          }, {
            "value": "agentequimico",
            "label": "Exposición a un agente químico como polvos, humos, vapores, gases y neblinas"
          }, {
            "value": "agentebiologico",
            "label": "Exposición a un agente biológico, como las bacterias, virus, hongos, desechos infecciosos y las infestaciones"
          }, {
            "value": "psicosociales",
            "label": "Riesgos psicosociales como entorno organizacional, jornada de trabajo, la exposición a acontecimientos traumáticos severos o a actos de violencia laboral al trabajador, por el trabajo desarrollado"
          }, {
            "value": "ergonomicos",
            "label": "Riesgos ergonómicos como movimientos repetitivos, manejo de cargas, posturas forzadas, cargas de trabajo, velocidad de trabajo y diseño del puesto de trabajo"
          }, {
            "value": "eqmalascondic",
            "label": "Que las herramientas, maquinaria o equipo estén en malas condiciones o que estén incompletos"
          }, {
            "value": "Value",
            "label": "Otro"
          }],
          "type": "checkbox-group",
          "nseleccionados": "3",
          "importancia": "No",
          "number_stepper": "3",
          "number_group": "0",
          "dato_abierto": "true",
          "required": "true",
          "name": "riesgostrabajo",
          "label": "34. Se&ntilde;ala los tres riesgos de trabajo que se presentan con mayor frecuencia en la empresa.",
          "class": "checkbox-group form-group col-md-7 ",
          "posicion": 49
        }, {
          "number_stepper": "3",
          "number_group": "0",
          "name": "gastocondiciones",
          "dato_abierto": "true",
          "class": "radio-group form-group col-md-7 ",
          "values": [{
            "value": "total",
            "label": "Totalmente de acuerdo"
          }, {
            "value": "deacuerdo",
            "label": "De acuerdo"
          }, {
            "value": "nia-nides",
            "label": "Ni de acuerdo, ni en desacuerdo"
          }, {
            "value": "des",
            "label": "En desacuerdo"
          }, {
            "value": "totaldes",
            "label": "Totalmente en desacuerdo"
          }],
          "label": "35. Es un gasto mejorar las &aacute;reas y condiciones de trabajo.",
          "required": "true",
          "type": "radio-group",
          "posicion": 50
        }, {
          "dato_abierto": "true",
          "number_group": "0",
          "number_stepper": "3",
          "type": "radio-group",
          "required": "true",
          "name": "comisionmixta",
          "values": [{
            "value": "si",
            "label": "Sí"
          }, {
            "value": "no",
            "label": "No"
          }],
          "class": "radio-group form-group col-md-7 ",
          "label": "36. &iquest;Existe una comisi&oacute;n mixta de capacitaci&oacute;n, adiestramiento y productividad en la empresa?",
          "posicion": 51
        }, {
          "number_stepper": "3",
          "number_group": "0",
          "dato_abierto": "true",
          "name": "plancapacitacion",
          "label": "37. &iquest;Cuenta con un plan anual de capacitaci&oacute;n basado en una detecci&oacute;n de necesidades?",
          "class": "radio-group form-group col-md-7 ",
          "type": "radio-group",
          "required": "true",
          "values": [{
            "value": "si",
            "label": "Sí"
          }, {
            "value": "no",
            "label": "No"
          }],
          "posicion": 52
        }, {
          "required": "true",
          "dato_abierto": "true",
          "number_group": "0",
          "number_stepper": "3",
          "type": "radio-group",
          "name": "competenciasdefinidas",
          "label": "38. &iquest;La empresa tiene definidas competencias para el desarrollo de las personas?",
          "class": "radio-group form-group col-md-7 ",
          "values": [{
            "value": "si",
            "label": "Sí"
          }, {
            "value": "no",
            "label": "No"
          }],
          "posicion": 53
        }, {
          "required": "true",
          "values": [{
            "value": "si",
            "label": "Sí"
          }, {
            "value": "no",
            "label": "No"
          }],
          "class": "radio-group form-group col-md-7 ",
          "dato_abierto": "true",
          "number_group": "0",
          "name": "evaluaciontrabcompetencias",
          "type": "radio-group",
          "number_stepper": "3",
          "label": "39. &iquest;Los trabajadores de la empresa son capacitados, evaluados y certificados por competencias?",
          "posicion": 54
        }, {
          "required": "true",
          "label": "40. &iquest;Cu&aacute;ntas horas promedio de capacitaci&oacute;n reciben los trabajadores al a&ntilde;o?",
          "class": "radio-group form-group col-md-7 ",
          "values": [{
            "value": "menos10",
            "label": "Menos de 10 horas"
          }, {
            "value": "10-20",
            "label": "De 10 a 20 horas"
          }, {
            "value": "20-30",
            "label": "De 21 a 30 horas"
          }, {
            "value": "mas31",
            "label": "Más de 31 horas"
          }, {
            "value": "noreciben",
            "label": "No reciben capacitación"
          }],
          "number_stepper": "3",
          "type": "radio-group",
          "number_group": "0",
          "dato_abierto": "true",
          "name": "hrspromcapacitacion",
          "posicion": 55
        }, {
          "values": [{
            "value": "desarrollo",
            "label": "Desarrollo de habilidades"
          }, {
            "value": "actualizacion",
            "label": "Actualización de conocimientos"
          }, {
            "value": "modificacion",
            "label": "Modificación de actitudes"
          }, {
            "value": "deshumano",
            "label": "Desarrollo humano"
          }, {
            "value": "desintegral",
            "label": "Desarrollo integral"
          }, {
            "value": "crecimientoorganiz",
            "label": "Crecimiento en la organización"
          }, {
            "value": "na",
            "label": "No aplica"
          }, {
            "value": "Value",
            "label": "Otro"
          }],
          "number_stepper": "3",
          "type": "radio-group",
          "required": "true",
          "class": "radio-group form-group col-md-7 ",
          "label": "41. La principal finalidad de la capacitaci&oacute;n que reciben los trabajadores es: ",
          "name": "finalidadcapacitacion",
          "number_group": "0",
          "dato_abierto": "true",
          "posicion": 56
        }, {
          "values": [{
            "value": "si",
            "label": "Sí"
          }, {
            "value": "no",
            "label": "No"
          }],
          "class": "radio-group form-group col-md-7 ",
          "label": "42. &iquest;Conoces la legislaci&oacute;n que aplica para la protecci&oacute;n del trabajo infantil y los adolescentes trabajadores en edad permitida? ",
          "name": "legislaciontrabinfantil",
          "required": "true",
          "type": "radio-group",
          "number_stepper": "3",
          "number_group": "0",
          "dato_abierto": "true",
          "posicion": 57
        }, {
          "number_group": "0",
          "number_stepper": "3",
          "regextype": "Sin Validaci&oacute;n",
          "subtype": "label",
          "type": "label",
          "name": "p42-afirmativocual",
          "maxlength": "200",
          "label": "En caso afirmativo, menciona cu&aacute;l ",
          "class": "form-control text-input form-group col-md-7 ",
          "dato_abierto": "true",
          "posicion": 58
        }, {
          "nseleccionados": "6",
          "importancia": "No",
          "type": "checkbox-group",
          "required": "true",
          "name": "doctospoliticas",
          "dato_abierto": "true",
          "number_group": "0",
          "number_stepper": "3",
          "label": "43. &iquest;En qu&eacute; documentos de la empresa se establecen los principios, pol&iacute;ticas, lineamientos y/o normatividad para el cuidado y protecci&oacute;n del trabajo infantil y los adolescentes trabajadores en edad permitida?",
          "class": "checkbox-group form-group col-md-7 ",
          "posicion": 59,
          "values": [{
            "value": "codetica",
            "label": "Código de ética"
          }, {
            "value": "mop",
            "label": "Manual de procedimientos"
          }, {
            "value": "reglamento",
            "label": "Reglamento"
          }, {
            "value": "perfiles",
            "label": "Perfiles de puesto"
          }, {
            "value": "contrato",
            "label": "Contrato de trabajo"
          }, {
            "value": "ninguno",
            "label": "Ninguno"
          }, {
            "value": "Value",
            "label": "Otro"
          }]

        }, {
          "number_stepper": "4",
          "class": "header form-group col-md-7 ",
          "label": "Igualdad",
          "name": "header-1492804445481",
          "type": "header",
          "subtype": "h6",
          "element": "Stepper",
          "number_group": "0",
          "dato_abierto": "true",
          "posicion": 60
        }, {
          "dato_abierto": "true",
          "number_stepper": "4",
          "textoapoyo": "Conciliaci&oacute;n entre vida laboral, familiar y personal e igualdad de oportunidades y trato igual para hombres y mujeres.",
          "element": "Label",
          "subtype": "h2",
          "type": "header",
          "name": "igualdad",
          "label": "IV. IGUALDAD",
          "class": " form-group col-md-7 ",
          "number_group": "0",
          "posicion": 61
        }, {
          "dato_abierto": "true",
          "required": "true",
          "number_stepper": "4",
          "number_group": "0",
          "type": "radio-group",
          "name": "codigoetica",
          "label": "44. &iquest;El c&oacute;digo de &eacute;tica contempla principios de igualdad y no discriminaci&oacute;n entre hombres y mujeres?",
          "class": "radio-group form-group col-md-7 ",
          "values": [{
            "value": "si",
            "label": "Sí"
          }, {
            "value": "no",
            "label": "No"
          }, {
            "value": "nocuenta",
            "label": "No cuenta con un código de ética"
          }],
          "posicion": 62
        }, {
          "dato_abierto": "true",
          "class": "form-control text-input form-group col-md-7 ",
          "number_group": "0",
          "number_stepper": "4",
          "regextype": "Sin Validaci&oacute;n",
          "subtype": "label",
          "type": "label",
          "name": "p44-afirmativo",
          "label": "En caso afirmativo, menciona cu&aacute;les ",
          "maxlength": "200",
          "posicion": 63
        }, {
          "class": "radio-group form-group col-md-7 ",
          "dato_abierto": "true",
          "name": "contratacionpaxdiscapacidad",
          "label": "45. Para la empresa es importante contratar personal con discapacidad.",
          "number_stepper": "4",
          "type": "radio-group",
          "required": "true",
          "number_group": "0",
          "posicion": 64,
          "values": [{
            "value": "total",
            "label": "Totalmente de acuerdo"
          }, {
            "value": "deacuerdo",
            "label": "De acuerdo"
          }, {
            "value": "nia-nides",
            "label": "Ni de acuerdo, ni en desacuerdo"
          }, {
            "value": "des",
            "label": "En desacuerdo"
          }, {
            "value": "totaldes",
            "label": "Totalmente en desacuerdo"
          }]

        }, {
          "label": "46. La empresa realiza acciones o pr&aacute;cticas orientadas a eliminar o disminuir la discriminaci&oacute;n por origen &eacute;tnico, sexo, edad, religi&oacute;n, nacionalidad o estado civil, en especial con grupos en situaci&oacute;n de vulnerabilidad (adultos mayores, j&oacute;venes, mujeres, personas con discapacidad o con VIH/SIDA). ",
          "dato_abierto": "true",
          "number_group": "0",
          "number_stepper": "4",
          "type": "radio-group",
          "required": "true",
          "name": "disminuirdiscriminacion",
          "class": "radio-group form-group col-md-7 ",
          "values": [{
            "value": "si",
            "label": "Sí"
          }, {
            "value": "no",
            "label": "No"
          }],

          "posicion": 65
        }, {
          "dato_abierto": "true",
          "maxlength": "200",
          "label": "En caso afirmativo, menciona cu&aacute;les son las acciones o pr&aacute;cticas que se llevan a cabo:",
          "regextype": "Sin Validaci&oacute;n",
          "subtype": "label",
          "type": "label",
          "name": "p46-cuales",
          "number_stepper": "4",
          "number_group": "0",
          "class": "form-control text-input form-group col-md-7 ",
          "posicion": 66
        }, {
          "number_group": "0",
          "class": "radio-group form-group col-md-7 ",
          "label": "50. &iquest;La empresa incluye en el seguro social a los trabajadores eventuales y temporales?",
          "name": "incluyesegurosocial",
          "required": "true",
          "type": "radio-group",
          "number_stepper": "5",
          "dato_abierto": "true",
          "values": [{
            "value": "si",
            "label": "Sí"
          }, {
            "value": "no",
            "label": "No"
          }, {
            "value": "na",
            "label": "No aplica"
          }],
          "posicion": 104
        }, {
          "importancia": "No",
          "class": "checkbox-group form-group col-md-7 ",
          "label": "51. Indica cu&aacute;les son las acciones que la empresa ha emprendido para construir un entorno amigable para la lactancia materna. ",
          "name": "entornoamigablelactancia",
          "required": "true",
          "type": "checkbox-group",
          "nseleccionados": "7",
          "number_stepper": "5",
          "number_group": "0",
          "dato_abierto": "true",
          "values": [{
            "value": "comunicacion",
            "label": "Comunicación oficial de la empresa, de apoyo a la lactancia materna"
          }, {
            "value": "desarrollo",
            "label": "Desarrollo e implementación de políticas para prevenir la discriminación a las mujeres embarazadas y madres, en particular durante el periodo de lactancia"
          }, {
            "value": "sensibilizacion",
            "label": "Sensibilización al personal de la empresa sobre la importancia de la lactancia materna"
          }, {
            "value": "capacitacion",
            "label": "Capacitación sobre la lactancia materna a las mujeres en edad reproductiva: beneficios, técnicas para extraer y conservar la leche materna, cómo mantener el amamantamiento en el trabajo y cómo plantear el destete, entre otras"
          }, {
            "value": "respetopromocion",
            "label": "Respeto, promoción y uso de reposos extraordinarios para lactancia previstos en la Ley Federal del Trabajo"
          }, {
            "value": "implementacion",
            "label": "Implementación de una sala de lactancia en un lugar adecuado para que las mujeres puedan extraer su leche durante la jornada laboral"
          }, {
            "value": "facilitaropciones",
            "label": "Facilitar opciones a las madres para promover la continuidad de la lactancia hasta que los bebés cumplan 24 meses, con acciones como: horarios flexibles, trabajo de tiempo parcial, trabajo desde su domicilio, acceso a guarderías en el lugar de trabajo"
          }, {
            "value": "ninguna",
            "label": "Ninguna"
          }],
          "posicion": 105
        }, {
          "dato_abierto": "true",
          "type": "radio-group",
          "required": "true",
          "name": "semanaslicmaternidad",
          "label": "52. El n&uacute;mero de semanas que se otorga a las mujeres por licencia de maternidad es de:",
          "class": "radio-group form-group col-md-7 ",
          "number_stepper": "5",
          "number_group": "0",
          "values": [{
            "value": "12",
            "label": "12"
          }, {
            "value": "14",
            "label": "14"
          }, {
            "value": "16",
            "label": "16"
          }, {
            "value": "18",
            "label": "18"
          }, {
            "value": "19",
            "label": "19 o más"
          }, {
            "value": "otro",
            "label": "Otro"
          }],

          "posicion": 106
        }, {
          "required": "true",
          "label": "53. Para el cumplimiento de obligaciones legales en materia de capacitaci&oacute;n, seguridad y salud, seguridad social, &iquest;de d&oacute;nde se asesora la empresa? ",
          "number_stepper": "5",
          "number_group": "0",
          "dato_abierto": "true",
          "class": "checkbox-group form-group col-md-7 ",
          "importancia": "No",
          "nseleccionados": "8",
          "type": "checkbox-group",
          "name": "obligacioneslegales",
          "values": [{
            "value": "stps",
            "label": "Secretaría del Trabajo y Previsión Social (Gobierno Federal)"
          }, {
            "value": "stgobedo",
            "label": "Secretaría del Trabajo (Gobierno del Estado)"
          }, {
            "value": "dft",
            "label": "Delegación Federal del Trabajo"
          }, {
            "value": "camaraempres",
            "label": "Cámara empresarial"
          }, {
            "value": "sindicato-conf",
            "label": "Sindicato-Confederación"
          }, {
            "value": "consultoria",
            "label": "Consultoría/asesoría"
          }, {
            "value": "internet",
            "label": "Internet"
          }, {
            "value": "ninguna",
            "label": "Ninguna"
          }, {
            "value": "Value",
            "label": "Otro"
          }],
          "posicion": 107
        }, {
          "type": "radio-group",
          "number_stepper": "5",
          "number_group": "0",
          "dato_abierto": "true",
          "name": "legislacionlaboralnegociosinformales",
          "required": "true",
          "class": "radio-group form-group col-md-7 ",
          "label": "54. Cumplir con la legislaci&oacute;n laboral dificulta competir con negocios informales.",
          "values": [{
            "value": "total",
            "label": "Totalmente de acuerdo"
          }, {
            "value": "deacuerdo",
            "label": "De acuerdo"
          }, {
            "value": "nia-nides",
            "label": "Ni de acuerdo, ni en desacuerdo"
          }, {
            "value": "des",
            "label": "En desacuerdo"
          }, {
            "value": "totaldes",
            "label": "Totalmente en desacuerdo"
          }],
          "posicion": 108
        }, {
          "number_group": "0",
          "required": "true",
          "name": "legislaclaboralniveleslimitaciones",
          "label": "55. Es posible cumplir con la legislaci&oacute;n laboral en sociedades con altos niveles de pobreza y limitaciones socio-econ&oacute;micas.",
          "class": "radio-group form-group col-md-7 ",
          "type": "radio-group",
          "dato_abierto": "true",
          "number_stepper": "5",
          "values": [{
            "value": "total",
            "label": "Totalmente de acuerdo"
          }, {
            "value": "deacuerdo",
            "label": "De acuerdo"
          }, {
            "value": "nia-nides",
            "label": "Ni de acuerdo, ni en desacuerdo"
          }, {
            "value": "des",
            "label": "En desacuerdo"
          }, {
            "value": "totaldes",
            "label": "Totalmente en desacuerdo"
          }],
          "posicion": 109
        }, {
          "required": "true",
          "dato_abierto": "true",
          "number_group": "0",
          "number_stepper": "5",
          "type": "radio-group",
          "name": "leyesregulaciones",
          "label": "56. Las leyes y regulaciones laborales son demasiadas para darles cumplimiento.",
          "class": "radio-group form-group col-md-7 ",
          "values": [{
            "value": "total",
            "label": "Totalmente de acuerdo"
          }, {
            "value": "deacuerdo",
            "label": "De acuerdo"
          }, {
            "value": "nia-nides",
            "label": "Ni de acuerdo, ni en desacuerdo"
          }, {
            "value": "des",
            "label": "En desacuerdo"
          }, {
            "value": "totaldes",
            "label": "Totalmente en desacuerdo"
          }],
          "posicion": 110
        }, {
          "number_stepper": "5",
          "number_group": "0",
          "dato_abierto": "true",
          "class": "radio-group form-group col-md-7 ",
          "label": "57. Las empresas que pagan impuestos ya cumplen con la legislaci&oacute;n laboral.",
          "name": "paganimptos",
          "required": "true",
          "type": "radio-group",
          "values": [{
            "value": "total",
            "label": "Totalmente de acuerdo"
          }, {
            "value": "deacuerdo",
            "label": "De acuerdo"
          }, {
            "value": "nia-nides",
            "label": "Ni de acuerdo, ni en desacuerdo"
          }, {
            "value": "des",
            "label": "En desacuerdo"
          }, {
            "value": "totaldes",
            "label": "Totalmente en desacuerdo"
          }],

          "posicion": 111
        }, {
          "type": "radio-group",
          "dato_abierto": "true",
          "number_group": "0",
          "class": "radio-group form-group col-md-7 ",
          "label": "58. Solo las grandes empresas y las multinacionales deben cumplir con la ley.",
          "name": "grmultinacionalesley",
          "required": "true",
          "number_stepper": "5",
          "values": [{
            "value": "total",
            "label": "Totalmente de acuerdo"
          }, {
            "value": "deacuerdo",
            "label": "De acuerdo"
          }, {
            "value": "nia-nides",
            "label": "Ni de acuerdo, ni en desacuerdo"
          }, {
            "value": "des",
            "label": "En desacuerdo"
          }, {
            "value": "totaldes",
            "label": "Totalmente en desacuerdo"
          }],
          "posicion": 112
        }, {
          "class": "radio-group form-group col-md-7 ",
          "label": "59. La seguridad social es muy cara y es mejor no registrar a los trabajadores en el IMSS.",
          "name": "segsocialcara",
          "required": "true",
          "type": "radio-group",
          "number_stepper": "5",
          "number_group": "0",
          "dato_abierto": "true",
          "values": [{
            "value": "total",
            "label": "Totalmente de acuerdo"
          }, {
            "value": "deacuerdo",
            "label": "De acuerdo"
          }, {
            "value": "nia-nides",
            "label": "Ni de acuerdo, ni en desacuerdo"
          }, {
            "value": "des",
            "label": "En desacuerdo"
          }, {
            "value": "totaldes",
            "label": "Totalmente en desacuerdo"
          }],
          "posicion": 113
        }, {
          "number_group": "0",
          "dato_abierto": "true",
          "class": "header form-group col-md-7 ",
          "label": "Di&aacute;logo",
          "name": "header-1492804606763",
          "type": "header",
          "subtype": "h6",
          "element": "Stepper",
          "number_stepper": "6",
          "posicion": 114
        }, {
          "number_stepper": "6",
          "textoapoyo": "Libertad de asociaci&oacute;n, participaci&oacute;n y di&aacute;logo social entre trabajadores, sindicato y empresarios.",
          "number_group": "0",
          "dato_abierto": "true",
          "class": " form-group col-md-7 ",
          "label": "VI. DI&Aacute;LOGO SOCIAL",
          "name": "dialogo",
          "type": "header",
          "subtype": "h2",
          "element": "Label",
          "posicion": 115
        }, {
          "required": "true",
          "number_stepper": "6",
          "importancia": "No",
          "number_group": "0",
          "dato_abierto": "true",
          "nseleccionados": "6",
          "class": "checkbox-group form-group col-md-7 ",
          "label": "60. Indica el tipo de participaci&oacute;n que tienen los trabajadores dentro de la empresa.",
          "name": "participaciontrabajadores",
          "type": "checkbox-group",
          "values": [{
            "value": "planeacion",
            "label": "Planeación operativa"
          }, {
            "value": "tomadecisiones",
            "label": "Toma de decisiones"
          }, {
            "value": "mejoracont",
            "label": "Mejora continua"
          }, {
            "value": "elabplancapacitac",
            "label": "Elaboración del plan anual de capacitación"
          }, {
            "value": "formulacprogramasst",
            "label": "Formulación del programa de seguridad y salud en el trabajo"
          }, {
            "value": "noparticipa",
            "label": "No participa"
          }, {
            "value": "otro",
            "label": "Otro"
          }],
          "posicion": 116
        }];

        formulario = formDataEspecial;
      }
      this.set("formplantilla", formulario);
    }

  });
});