define('dynforms-client/components/id-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNameBindings: ['isEnabled:enabled:disabled'],
    isEnabled: false
  });
});