define('dynforms-client/models/reporte', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		url: _emberData.default.attr('string'),
		vigente: _emberData.default.attr('string'),
		id_dependencia: _emberData.default.attr('number')
	});
});