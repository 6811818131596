define('dynforms-client/views/tramites/new', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var tramitesNew = Ember.View.extend({
		didInsertElement: function didInsertElement() {},
		actions: {
			addScheduleItem: function addScheduleItem(payload) {
				// VALIDATIONS

				if (payload.phoneNum !== undefined && payload.initHour !== undefined && payload.endHour !== undefined) {
					//payload.initTime = '10:10';
					//payload.set('entTime', payload.endHour+':'+payload.endMins);

					this.get('controller').send('addScheduleItem', payload);
				} else {
					alert('Por favor completa todos los campos');
				}
			},
			addCostoItem: function addCostoItem() {
				var costo = this.get('controller').get('costo');
				if (costo.descripcion !== undefined && costo.precio !== undefined) {
					this.get('controller').send('addCostoItem');
				} else {
					alert('Por favor completa todos los campos');
				}
			}
		}
	});
	exports.default = tramitesNew;
});