define('dynforms-client/helpers/current-date', ['exports', 'dynforms-client/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.currentDate = currentDate;
  function currentDate(input) {
    return moment(input).format('DD/MM/YYYY');
  }

  // export default Ember.Handlebars.makeBoundHelper(currentDate);
  exports.default = Ember.Helper.helper(currentDate);
});