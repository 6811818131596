define('dynforms-client/helpers/block-form', ['exports', 'dynforms-client/config/environment'], function (exports, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.blockForm = blockForm;
	function blockForm(lock) {
		if (lock) {
			var msg = "El formulario esta bloqueado por que otro usuario lo tiene abierto y no puede ser editado en este momento";
			var botones = { "Aceptar": true };
			/*$.prompt(msg , {
   	overlayspeed:'fast',
   	promptspeed:'fast',
   	title: "Mensaje de formularios",
   	buttons:botones,
   	submit: function(e,answer){
   		if(answer === true){
   			var  url =  "/dynforms/";
   			window.location= url;
   		}
   	}
   });*/
		}
	}
	// export default Ember.Handlebars.makeBoundHelper(blockForm);
	exports.default = Ember.Helper.helper(blockForm);
});