define('dynforms-client/models/action-type', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		description: _emberData.default.attr('string'),
		link: _emberData.default.attr('string'),
		tipo: _emberData.default.attr('string'),
		order: _emberData.default.attr('number'),
		formality: _emberData.default.attr('number')
	});
});