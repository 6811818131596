define('dynforms-client/components/pagination-base', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'button',
    classNames: 'btn btn-default'.w(),
    attributeBindings: ['disabled'],
    // the enabled property will easily toggle the disabled attribute for the element
    // in case there's no more items to iterate
    enabled: true,
    disabled: Ember.computed.not('enabled'),
    action: null,
    click: function click() {
      console.log('click en boton');
      this.sendAction();
    }
  });
});