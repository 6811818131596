define('dynforms-client/components/class-active', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    click: function click() {
      alert('DoubleClickableComponent was clicked!');
    }
  });
});